import React from "react";

import { useHistory } from 'react-router-dom';

import { Container, Paragraph } from './styles';

import { MdKeyboardBackspace } from "react-icons/md";

const BackRouter: React.FC = () => {
  
  const history = useHistory();

  return (
    <>
      <Container onClick={() => history.goBack()}>
        <MdKeyboardBackspace />
        <Paragraph>Voltar</Paragraph>
      </Container>
    </>
  );
};

export default BackRouter;
