import React from "react";

import { AuthProvider as Auth } from "./auth";
import { ForgotPasswordProvider } from "./forgotPassword";
import { ChangePasswordProvider as ChangePassword } from "./changePassword";
import { UpdatePasswordProvider as UpdatePassword } from "./updatePasswordForPrimaryAccess";
import { ListActivityProvider as ListActivity } from "./listActivity";
import { RequestModalProvider as RequestModal } from "./requestModal";
import { SubscribeActivityProvider as SubscribeActivity } from "./subscribeAcitivity";
import { ListActivitysSubscribeProvider as ListActivitysSubscribe } from "./listActivitysSubscribe";
import { StudentProvider as StudentData } from "./studendData";
import { OccupationStateProvider as OccupationState } from "./occupationState";

const AuthProvider: React.FC = ({ children }) => <Auth>{children}</Auth>;

const ForgotProvider: React.FC = ({ children }) => (
  <ForgotPasswordProvider>{children}</ForgotPasswordProvider>
);

const ChangePasswordProvider: React.FC = ({ children }) => (
  <ChangePassword>{children}</ChangePassword>
);

const UpdatePasswordProvider: React.FC = ({ children }) => (
  <UpdatePassword>{children}</UpdatePassword>
);

const ListActivityProvider: React.FC = ({ children }) => (
  <ListActivity>{children}</ListActivity>
);

const RequestModalProvider: React.FC = ({ children }) => (
  <RequestModal>{children}</RequestModal>
);

const SubscribeActivityProvider: React.FC = ({ children }) => (
  <SubscribeActivity>{children}</SubscribeActivity>
);

const ListActivitysSubscribeProvider: React.FC = ({ children }) => (
  <ListActivitysSubscribe>{children}</ListActivitysSubscribe>
);

const StudentProvider: React.FC = ({ children }) => (
  <StudentData>{children}</StudentData>
);

const OccupationStateProvider: React.FC = ({ children }) => (
  <OccupationState>{children}</OccupationState>
);

export {
  AuthProvider,
  ForgotProvider,
  ChangePasswordProvider,
  UpdatePasswordProvider,
  ListActivityProvider,
  RequestModalProvider,
  SubscribeActivityProvider,
  ListActivitysSubscribeProvider,
  StudentProvider,
  OccupationStateProvider
};
