import React, { ButtonHTMLAttributes } from "react";

import { Button as MaterialButton } from "@material-ui/core";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme: string;
  hidden: boolean;
  display?: string;
  align?: string;
  justify?: string;
  left?: string;
  right?: string;
  top?: string;
  onPressed?: any;
  isSubmit?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  theme,
  hidden,
  display,
  align,
  justify,
  left,
  right,
  top,
  onPressed,
  isSubmit,
}) => {
  return (
    <MaterialButton
      type={isSubmit === false ? 'button' : 'submit'}
      variant="contained"
      disabled={hidden}
      color="primary"
      style={{
        backgroundColor:
          hidden === true
            ? "#e0e0e0"
            : theme === "primary"
            ? "#0091ea"
            : theme === "secondary"
            ? "#f48fb1"
            : theme === "success"
            ? "#00c853"
            : theme === "other"
            ? "#FFF"
            : "#e0e0e0",
        color: hidden === true || theme === "other" ? "#000" : "#FFF",
        display: display && display,
        alignItems: align && align,
        justifyContent: justify && justify,
        marginLeft: left && left,
        marginRight: right && right,
        marginTop: top && top,
        border: theme === "other" ? "1px solid #000" : "none",
      }}
      onClick={onPressed && onPressed}
    >
      {children}
    </MaterialButton>
  );
};

export default Button;
