import React, { useState, useCallback, FormEvent } from "react";

import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid, TextField } from "@material-ui/core";

import Button from "../../../../../components/Button";
import Card from "../../../../../components/Card";
import Navbar from "../../../../../components/Navbar";

import { useAuth } from "../../../../../hooks/auth";
import api from "../../../../../services/api";
import { AxiosResponse } from "axios";
import { showToastr } from "../../../../../components/Toastr";
import { toast } from "react-toastify";
import BackRouter from "../../../../../components/BackRouter";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
    display: "flex",
    overflow: "hidden",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#0064b7",

    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#0091ea",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const RegisterEvent: React.FC = () => {
  const [titleEvent, setTitleEvent] = useState("");
  const [descriptionEvent, setDescriptionEvent] = useState("");
  const [dateEvent, setDateEvent] = useState("");
  const [scheduleEvent, setScheduleEvent] = useState("");
  const [durationEvent, setDurationEvent] = useState("");
  const [placeEvent, setPlaceEvent] = useState("");
  const [quantityStudents, setQuantityStudents] = useState(0);
  const [limitSubscribeDate, setLimitSubscribeDate] = useState("");

  const [titleInvalid, setTitleInvalid] = useState<boolean>(false);
  const [descriptionInvalid, setDescriptionInvalid] = useState<boolean>(false);
  const [dateInvalid, setDateInvalid] = useState<boolean>(false);
  const [hourInvalid, setHourInvalid] = useState<boolean>(false);
  const [durationInvalid, setDurationInvalid] = useState<boolean>(false);
  const [placeInvalid, setPlaceInvalid] = useState<boolean>(false);
  const [quantityInvalid, setQuantityInvalid] = useState<boolean>(false);
  const [limitDateInvalid, setLimitDateInvalid] = useState<boolean>(false);

  const [
    limitSubscribeDateInvalid,
    setLimitSubscribeDateInvalid,
  ] = useState<boolean>(false);

  const [persons, setPersons] = useState([]);

  const [loader, setLoader] = useState<boolean>(false);

  const { user } = useAuth();

  const classes = useStyles();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const data = {
        titleEvent,
        descriptionEvent,
        dateEvent,
        scheduleEvent,
        durationEvent,
        placeEvent,
        quantityStudents,
        limitSubscribeDate,
      };
      try {
        setLoader(true);
        await api({
          method: "post",
          url: "activity",
          data: {
            title: data.titleEvent,
            description: data.descriptionEvent,
            date: data.dateEvent,
            hours: data.scheduleEvent,
            duration: data.durationEvent,
            place: data.placeEvent,
            deadline: data.limitSubscribeDate,
            quantity: data.quantityStudents,
            organization_id: user.organization_id,
            types_activities_id: 1,
          },
          headers: {
            "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
          }
        })
          .then((response) => {
            setLoader(false);

            setTitleInvalid(false);
            setDescriptionInvalid(false);
            setDateInvalid(false);
            setHourInvalid(false);
            setDurationInvalid(false);
            setPlaceInvalid(false);
            setQuantityInvalid(false);
            setLimitSubscribeDateInvalid(false);
            setLimitDateInvalid(false);

            setTitleEvent("");
            setDescriptionEvent("");
            setDateEvent("");
            setScheduleEvent("");
            setDurationEvent("");
            setPlaceEvent("");
            setQuantityStudents(0);
            setLimitSubscribeDate("");
            showToastr(
              "O evento foi cadastrado com sucesso",
              "success",
              toast.POSITION.BOTTOM_RIGHT
            );
          })
          .catch((err) => {
            setLoader(false);
            if (err.response.status === 401) {
              showToastr(
                "Você não tem a permissão para cadastrar eventos na plataforma! Contate a equipe de suporte",
                "error",
                toast.POSITION.BOTTOM_RIGHT
              );
            } else if (err.response.status === 400) {
              console.log(err.response.data.error);
              switch (err.response.data.error) {
                case "Adicione um título":
                  setTitleInvalid(true);
                  setDescriptionInvalid(false);
                  setDateInvalid(false);
                  setHourInvalid(false);
                  setDurationInvalid(false);
                  setPlaceInvalid(false);
                  setQuantityInvalid(false);
                  showToastr(
                    "Informe um título válido para o evento",
                    "error",
                    toast.POSITION.BOTTOM_RIGHT
                  );
                  break;
                case "Adicione uma descrição":
                  setDescriptionInvalid(true);
                  setTitleInvalid(false);
                  setDateInvalid(false);
                  setHourInvalid(false);
                  setDurationInvalid(false);
                  setPlaceInvalid(false);
                  setQuantityInvalid(false);
                  showToastr(
                    "Informe uma descrição válida para o evento",
                    "error",
                    toast.POSITION.BOTTOM_RIGHT
                  );
                  break;
                case "Adicione uma data":
                  setDateInvalid(true);
                  setTitleInvalid(false);
                  setDescriptionInvalid(false);
                  setHourInvalid(false);
                  setDurationInvalid(false);
                  setPlaceInvalid(false);
                  setQuantityInvalid(false);
                  showToastr(
                    "Informe uma data válida para o evento",
                    "error",
                    toast.POSITION.BOTTOM_RIGHT
                  );
                  break;
                case "Adicione uma data limite":
                  setLimitSubscribeDateInvalid(true);
                  setDateInvalid(false);
                  setTitleInvalid(false);
                  setDescriptionInvalid(false);
                  setHourInvalid(false);
                  setDurationInvalid(false);
                  setPlaceInvalid(false);
                  setQuantityInvalid(false);
                  showToastr(
                    "Informe uma data limite válida para as inscrições do evento",
                    "error",
                    toast.POSITION.BOTTOM_RIGHT
                  );
                  break;
                case "Data tem que ser maior que hoje":
                  setLimitSubscribeDateInvalid(false);
                  setDateInvalid(true);
                  setTitleInvalid(false);
                  setDescriptionInvalid(false);
                  setHourInvalid(false);
                  setDurationInvalid(false);
                  setPlaceInvalid(false);
                  setQuantityInvalid(false);
                  showToastr(
                    "Informe uma data válida para cadastrar o evento",
                    "error",
                    toast.POSITION.BOTTOM_RIGHT
                  );
                  break;
                case "Data limite tem que maior que hoje":
                  setLimitSubscribeDateInvalid(true);
                  setDateInvalid(false);
                  setTitleInvalid(false);
                  setDescriptionInvalid(false);
                  setHourInvalid(false);
                  setDurationInvalid(false);
                  setPlaceInvalid(false);
                  setQuantityInvalid(false);
                  showToastr(
                    "Informe uma data limite válida para cadastrar o evento",
                    "error",
                    toast.POSITION.BOTTOM_RIGHT
                  );
                  break;
                case "Data limite tem que ser menor que data da atividade":
                  showToastr(
                    "A data limite para inscrições deve ocorrer antes da data do evento",
                    "error",
                    toast.POSITION.BOTTOM_RIGHT
                  );
                  break;
                default:
                  showToastr(
                    "Erro interno no servidor, tente novamente!",
                    "error",
                    toast.POSITION.BOTTOM_RIGHT
                  );
                  break;
              }
            } else {
              showToastr(
                "Não foi possível realizar o cadastro do evento na plataforma! Tente novamente mais tarde!",
                "error",
                toast.POSITION.BOTTOM_RIGHT
              );
            }
          });
      } catch (err) {
        showToastr(
          "Erro interno no servidor",
          "error",
          toast.POSITION.BOTTOM_RIGHT
        );
      }
    },
    [
      dateEvent,
      descriptionEvent,
      durationEvent,
      limitSubscribeDate,
      placeEvent,
      quantityStudents,
      scheduleEvent,
      titleEvent,
      user.organization_id,
    ]
  );

  return (
    <Navbar permission="director">
      <main
        className={classes.content}
        style={{ overflowX: "hidden", height: "150vh" }}
      >
        <form onSubmit={handleSubmit}>
          <div className={classes.appBarSpacer} />
          <BackRouter />
          <h1
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: "75px",
              marginTop: "56px",
            }}
          >
            Adicionar Evento
          </h1>
          <p style={{ marginLeft: "73px", fontSize: "12px" }}>
            Cadastre um evento para que os alunos possam realiza suas
            inscrições.
          </p>
          <Card>
            <Grid container spacing={3} style={{ maxWidth: "1114px" }}>
              <Grid item xs={12} sm={6} style={{ maxWidth: "522px" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  error={titleInvalid}
                  helperText={
                    titleInvalid && "Informe um título válido para o evento"
                  }
                  label="Título"
                  placeholder="Informe um título para o evento"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={titleEvent}
                  onChange={(e) => setTitleEvent(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ maxWidth: "540px" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  error={descriptionInvalid}
                  helperText={
                    descriptionInvalid &&
                    "Informe uma descrição válida para o evento"
                  }
                  label="Descrição"
                  placeholder="Informe uma breve descrição para o evento"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={descriptionEvent}
                  onChange={(e) => setDescriptionEvent(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ maxWidth: "364px" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  error={dateInvalid}
                  helperText={
                    dateInvalid && "Informe uma data válida para o evento"
                  }
                  label="Data do Evento"
                  placeholder="Informe uma breve descrição para o evento"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dateEvent}
                  onChange={(e) => setDateEvent(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ maxWidth: "290px" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  error={hourInvalid}
                  helperText={
                    hourInvalid && "Informe um horário válido para o evento"
                  }
                  label="Horário"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={scheduleEvent}
                  onChange={(e) => setScheduleEvent(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ maxWidth: "356px" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  error={durationInvalid}
                  helperText={
                    durationInvalid &&
                    "Informe uma duração válida para o evento"
                  }
                  label="Duração"
                  placeholder="Informe a duração do evento"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={durationEvent}
                  onChange={(e) => setDurationEvent(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ maxWidth: "365px" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  error={placeInvalid}
                  helperText={
                    placeInvalid && "Informe um local válido para o evento"
                  }
                  label="Local"
                  placeholder="Informe o local do evento"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={placeEvent}
                  onChange={(e) => setPlaceEvent(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ maxWidth: "356px" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  error={quantityInvalid}
                  helperText={
                    quantityInvalid &&
                    "Informe uma quantidade de alunos válida para o evento"
                  }
                  label="Quantidade de Alunos"
                  placeholder="Informe a quantidade de alunos"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="number"
                  value={quantityStudents}
                  onChange={(e) => setQuantityStudents(Number(e.target.value))}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ maxWidth: "295px" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  error={limitSubscribeDateInvalid}
                  helperText={
                    limitSubscribeDateInvalid &&
                    "Informe uma data limite para realizar as inscrições"
                  }
                  label="Data limite para inscrições"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  placeholder="Data limite"
                  value={limitSubscribeDate}
                  onChange={(e) => setLimitSubscribeDate(e.target.value)}
                />
              </Grid>
            </Grid>
          </Card>
          <div
            id="buttons"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              maxWidth: "1155px",
              marginTop: "37px",
            }}
          >
            {loader ? (
              <CircularProgress />
            ) : (
              <Button type="submit" theme="success" hidden={false}>
                SALVAR EVENTO
              </Button>
            )}
          </div>
        </form>
      </main>
    </Navbar>
  );
};
export default RegisterEvent;
