import React, { useState, useEffect } from "react";

import api from "../../../services/api";

import MaterialTable from "material-table";

import IDataTableProps from "../interface";
import IActivitySubscribeProps from "../../../models/ListSubscribersInterface";

import appColors from "../../../shared/app_colors";

import Modal from "../../../components/Modal";

import { useAuth } from "../../../hooks/auth";
import { useSubscribeActivity } from "../../../hooks/subscribeAcitivity";
import { useRequestModal } from "../../../hooks/requestModal";

const DataTableSubscribers: React.FC<IDataTableProps> = ({
  activityType,
  action,
  methods,
  ...rest
}) => {
  const [activitys, setActivitys] = useState<any>([]);
  const [activityTitle, setActivityTitle] = useState<any>([]);
  const [activityDate, setActivityDate] = useState<any>([]);
  const [activityHour, setActivityHour] = useState<any>([]);
  const [activityPlace, setActivityPlace] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const { user } = useAuth();
  const { requestModal, closeModal } = useRequestModal();
  const { subscribeActivity, showLoader } = useSubscribeActivity();

  useEffect(() => {
    setLoader(true);
    api
      .get("/student", {
        params: {
          user_id: user.id,
        },
        headers: {
          "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
        }
      })
      .then((response) => {
        api
          .get("/subscribe", {
            params: {
              students_id: response.data[0]?.id,
            },
            headers: {
              "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
            }
          })
          .then((response) => {
            setActivitys(response.data);
          });
      });
  }, []);

  return (
    <>
      <Modal
        isLoading={showLoader}
        message={`
          Você se inscreveu no ${
            activityType === "event" ? "evento" : "curso"
          } ${activityTitle} que ocorrerá no dia ${activityDate} ?
        `}
        buttonLeftMessage="VOLTAR"
        buttonRightMessage="ENTENDI"
        actions={() => closeModal()}
      />
      <MaterialTable
        title="Cursos e Eventos inscritos"
        onRowClick={(event, rowData) => {
          setActivityTitle(rowData?.titleActivity);
          setActivityDate(rowData?.dateActivity);
          requestModal({ openModal: true });
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "Nenhum resultado encontrado...",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "linhas",
            labelRowsPerPage: "linhas por página:",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
          },
          toolbar: {
            searchTooltip: "Pesquisar evento",
            searchPlaceholder: "Pesquisar",
            exportAriaLabel: "Exportar",
            exportTitle: "Exportar",
            exportCSVName: "Exportar para EXCEL",
            exportPDFName: "Exportar para PDF",
          },
          header: {
            actions: "Ações",
          },
        }}
        columns={[
          { title: "Título", field: "titleActivity" },
          { title: "Descrição", field: "descriptionActivity" },
          { title: "Data", field: "dateActivity" },
        ]}
        data={activitys}
        actions={[
          (rowData) => ({
            icon: "search",
            tooltip: "Detalhes do evento",
            iconProps: { style: { color: appColors.siecStandarGrey } },
            onClick: (event, rowData) => console.log('SISDUCACIONAL'),
            disabled: true,
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          exportButton: true,
          exportFileName:
            activityType === "event" ? "EVENTOS INSCRITOS" : "CURSOS INSCRITOS",
        }}
        style={{ width: "100%" }}
      />
    </>
  );
};

export default DataTableSubscribers;
