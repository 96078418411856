/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid } from "@material-ui/core";

import { toast } from "react-toastify";
import { showToastr } from "../../components/Toastr";

import Footer from "../../components/Footer";

import Icon from "../../components/Icon";
import Button from "../../components/Button";

import ClibBoardCardIcon from "../../assets/icons/icon_clipboard_outline.svg";
import EventCardIcon from "../../assets/icons/icon_event_admin_outline.svg";
import EventSubscribeCardIcon from "../../assets/icons/icon_event_subscribe.svg";
import Navbar from "../../components/Navbar";

import { useAuth } from "../../hooks/auth";
import { Link } from "react-router-dom";
import BackRouter from "../../components/BackRouter";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
    display: "flex",
    overflow: "hidden",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#0064b7",

    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#0091ea",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
}));

export default function Director() {
  const { user } = useAuth();

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navbar permission="director">
        <main className={classes.content} style={{ overflowX: "hidden" }}>
          <div className={classes.appBarSpacer} />
          <div>
            <h1
              style={{
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize: "48px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginLeft: "75px",
                marginTop: "56px",
              }}
            >
              Acesso Rápido
            </h1>
            <p style={{ marginLeft: "73px", fontSize: "12px" }}>
              Acesse aqui as funcionalidades do sistema.
            </p>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <div
                  style={{
                    maxWidth: "337px",
                    height: "350px",
                    backgroundColor: "#FFF",
                    marginLeft: "37px",
                    border: "1px solid #eee",
                    borderRadius: "4px",
                    marginTop: "68px",
                  }}
                >
                  <Icon
                    path={ClibBoardCardIcon}
                    size="142px"
                    left="32px"
                    top="25px"
                    right="75px"
                  />
                  <h2
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "30px",
                      marginTop: "11px",
                    }}
                  >
                    Adicionar
                  </h2>
                  <h2
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "30px",
                      marginTop: "-11px",
                    }}
                  >
                    Evento
                  </h2>
                  <p
                    style={{
                      fontFamily: "Roboto",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "30px",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <Button
                    type="button"
                    theme="primary"
                    hidden={false}
                    display="flex"
                    align="center"
                    justify="center"
                    left="27px"
                    top="7px"
                  >
                    <Link
                      to="/registerEvent"
                      style={{
                        textDecoration: "none",
                        color: "#FFF",
                      }}
                    >
                      ACESSAR
                    </Link>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div
                  style={{
                    maxWidth: "337px",
                    height: "350px",
                    backgroundColor: "#FFF",
                    marginLeft: "37px",
                    border: "1px solid #eee",
                    borderRadius: "4px",
                    marginTop: "68px",
                  }}
                >
                  <Icon
                    path={EventCardIcon}
                    size="153px"
                    left="32px"
                    top="25px"
                    right="75px"
                  />
                  <h2
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "30px",
                      marginTop: "36px",
                    }}
                  >
                    Eventos
                  </h2>
                  <h2
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "30px",
                      marginTop: "-11px",
                    }}
                  >
                    Disponíveis
                  </h2>
                  <p
                    style={{
                      fontFamily: "Roboto",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "30px",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <Button
                    type="button"
                    theme="primary"
                    hidden={false}
                    display="flex"
                    align="center"
                    justify="center"
                    left="27px"
                    top="10px"
                  >
                    <Link
                      to="/viewEvents"
                      style={{
                        textDecoration: "none",
                        color: "#FFF",
                      }}
                    >
                      ACESSAR
                    </Link>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div
                  style={{
                    maxWidth: "337px",
                    height: "350px",
                    backgroundColor: "#FFF",
                    marginLeft: "37px",
                    border: "1px solid #eee",
                    borderRadius: "4px",
                    marginTop: "68px",
                  }}
                >
                  <Icon
                    path={ClibBoardCardIcon}
                    size="142px"
                    left="32px"
                    top="25px"
                    right="75px"
                  />
                  <h2
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "30px",
                      marginTop: "11px",
                    }}
                  >
                    Adicionar
                  </h2>
                  <h2
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "30px",
                      marginTop: "-11px",
                    }}
                  >
                    Curso
                  </h2>
                  <p
                    style={{
                      fontFamily: "Roboto",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "30px",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <Button
                    type="button"
                    theme="primary"
                    hidden={false}
                    display="flex"
                    align="center"
                    justify="center"
                    left="27px"
                    top="7px"
                  >
                    <Link
                      to="/registerCourse"
                      style={{
                        textDecoration: "none",
                        color: "#FFF",
                      }}
                    >
                      ACESSAR
                    </Link>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div
                  style={{
                    maxWidth: "337px",
                    height: "350px",
                    backgroundColor: "#FFF",
                    marginLeft: "37px",
                    border: "1px solid #eee",
                    borderRadius: "4px",
                    marginTop: "68px",
                  }}
                >
                  <Icon
                    path={EventCardIcon}
                    size="153px"
                    left="32px"
                    top="25px"
                    right="75px"
                  />
                  <h2
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "30px",
                      marginTop: "36px",
                    }}
                  >
                    Cursos
                  </h2>
                  <h2
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "30px",
                      marginTop: "-11px",
                    }}
                  >
                    Disponíveis
                  </h2>
                  <p
                    style={{
                      fontFamily: "Roboto",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "30px",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <Button
                    type="button"
                    theme="primary"
                    hidden={false}
                    display="flex"
                    align="center"
                    justify="center"
                    left="27px"
                    top="10px"
                  >
                    <Link
                      to="/viewCourses"
                      style={{
                        textDecoration: "none",
                        color: "#FFF",
                      }}
                    >
                      ACESSAR
                    </Link>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div
                  style={{
                    maxWidth: "337px",
                    height: "350px",
                    backgroundColor: "#FFF",
                    marginLeft: "37px",
                    border: "1px solid #eee",
                    borderRadius: "4px",
                    marginTop: "68px",
                  }}
                >
                  <Icon
                    path={EventCardIcon}
                    size="153px"
                    left="32px"
                    top="25px"
                    right="75px"
                  />
                  <h2
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "30px",
                      marginTop: "36px",
                    }}
                  >
                    Adicionar
                  </h2>
                  <h2
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "30px",
                      marginTop: "-11px",
                    }}
                  >
                    Aluno
                  </h2>
                  <p
                    style={{
                      fontFamily: "Roboto",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "30px",
                    }}
                  >
                    Cadastre um aluno no sistema
                  </p>
                  <Button
                    type="button"
                    theme="primary"
                    hidden={false}
                    display="flex"
                    align="center"
                    justify="center"
                    left="27px"
                    top="10px"
                  >
                    <Link
                      to="/registerStudent"
                      style={{
                        textDecoration: "none",
                        color: "#FFF",
                      }}
                    >
                      ACESSAR
                    </Link>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div
                  style={{
                    maxWidth: "337px",
                    height: "350px",
                    backgroundColor: "#FFF",
                    marginLeft: "37px",
                    border: "1px solid #eee",
                    borderRadius: "4px",
                    marginTop: "68px",
                  }}
                >
                  <Icon
                    path={EventCardIcon}
                    size="153px"
                    left="32px"
                    top="25px"
                    right="75px"
                  />
                  <h2
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "30px",
                      marginTop: "36px",
                    }}
                  >
                    Adicionar
                  </h2>
                  <h2
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "30px",
                      marginTop: "-11px",
                    }}
                  >
                    Funcionário
                  </h2>
                  <p
                    style={{
                      fontFamily: "Roboto",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "30px",
                    }}
                  >
                    Cadastre um funcionário no sistema
                  </p>
                  <Button
                    type="button"
                    theme="primary"
                    hidden={false}
                    display="flex"
                    align="center"
                    justify="center"
                    left="27px"
                    top="10px"
                  >
                    <Link
                      to="/registerEmployee"
                      style={{
                        textDecoration: "none",
                        color: "#FFF",
                      }}
                    >
                      ACESSAR
                    </Link>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
          <Footer />
        </main>
      </Navbar>
    </div>
  );
}
