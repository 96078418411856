import React from 'react';

import { Container } from './styles';

interface CardProps {
  mt?: string | number;
  ml?: string | number;
  mr?: string | number;
  padding?: string | number;
  radius?: string | number;
}

const Card: React.FC<CardProps> = ({ children, mt, ml, mr, padding, radius }) => {
  return (
    <Container
      style={{
        marginTop: mt && mt,
        marginLeft: ml && ml,
        marginRight: mr && mr,
        padding: padding && padding,
        borderRadius: radius && radius
      }}
    >
      {children}
    </Container>
  );
}

export default Card;