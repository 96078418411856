import React, { useEffect, useState } from "react";
import Card from "../../../components/Card";
import Title from "../../../components/Title";
import AccordionComponent from "../../../components/Accordion";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useAuth } from "../../../hooks/auth";

import Navbar from "../../../components/Navbar";
import BackRouter from "../../../components/BackRouter";

import IconFeature from "../../../assets/icons/icon_course.svg";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
    display: "flex",
    overflow: "hidden",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#0064b7",

    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#0091ea",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
}));

const DirectorAccount: React.FC = () => {
  const [occupation, setOccupation] = useState<string>();

  const { user } = useAuth();

  const classes = useStyles();

  useEffect(() => {
    switch (user.role_id) {
      case 1:
        setOccupation("Administrador");
        break;
      case 2:
        setOccupation("Diretor");
        break;
      case 3:
        setOccupation("Aluno");
        break;
      case 4:
        setOccupation("Funcionário");
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      <Navbar permission="director">
        <main
          className={classes.content}
          style={{ overflowX: "hidden", height: "300vh" }}
        >
          <form onSubmit={() => {}}>
            <div className={classes.appBarSpacer} />
            <BackRouter />
            <h1
              style={{
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginLeft: "75px",
                marginTop: "56px",
              }}
            >
              Minha Conta
            </h1>
            <p style={{ marginLeft: "73px", fontSize: "12px" }}>
              Confira aqui os dados do seu perfi.
            </p>
            <Card>
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "70px",
                }}
              >
                <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                  <Title fontFamily="Roboto" weight="bold">
                    Nome
                  </Title>
                  <p>{user.name}</p>
                </Grid>
                <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                  <Title fontFamily="Roboto" weight="bold">
                    Email
                  </Title>
                  <p>{user.email}</p>
                </Grid>
                <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                  <Title fontFamily="Roboto" weight="bold">
                    Ocupação
                  </Title>
                  <p>{occupation}</p>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "70px",
                }}
              ></Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "70px",
                }}
              >
                <Grid item xs={12} sm={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center"
                    }}
                  >
                    <h2>
                      Estamos preparando algo novo! Em breve mais ferramentas
                      estarão disponível aqui pra você usar.
                    </h2>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </form>
        </main>
      </Navbar>
    </>
  );
};

export default DirectorAccount;
