/* eslint-disable no-restricted-globals */
import React from "react";

import useStyles from "../../utils/useStyles";

import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import { Box } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

import Avatar from "../Avatar";
import Icon from "../Icon";

import { useAuth } from "../../hooks/auth";

import UserIcon from "../../assets/icons/icon_user_outline.svg";
import LogOutIcon from "../../assets/icons/log_out_icon_outline.svg";

import { MdKeyboardArrowDown as ExpandOption } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";

interface NavbarProps {
  permission: string;
}

const Navbar: React.FC<NavbarProps> = ({ children, permission }) => {
  const [open, setOpen] = React.useState(false);

  const { user } = useAuth();

  const classes = useStyles();

  const history = useHistory();

  function logOut() {
    localStorage.removeItem("@SIEC:token");
    localStorage.removeItem("@SIEC:user");
    location.href = '/';
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
        style={{ background: 'rgb(0, 135, 234)'}}
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
           Escola Estadual {user.organization_name}
          </Typography>
          <Badge color="secondary">
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState: any) => (
                <div>
                  <div
                    {...bindTrigger(popupState)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      email={user.email}
                      hidden={true}
                      alias={user.name
                        ?.split(" ")
                        .map((word: any, index: any) =>
                          index <= 1 ? word[0] : ""
                        )
                        .join("")}
                    />
                    <ExpandOption
                      style={{
                        width: "30px",
                        height: "18px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <Box p={2} style={{ marginRight: "15px" }}>
                      <Typography
                        style={{
                          fontSize: "18px",
                          cursor: "pointer",
                          display: "flex",
                        }}
                      >
                        <Icon path={UserIcon} size="16px" right="17px" />
                        <Link
                          to={
                            permission === "director"
                              ? "/directorAccount"
                              : permission === "employee"
                              ? "/employeeAccount"
                              : "/studentAccount"
                          }
                          style={{
                            textDecoration: "none",
                            color: "#000",
                          }}
                        >
                          Minha Conta
                        </Link>
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "18px",
                          cursor: "pointer",
                          display: "flex",
                        }}
                        onClick={logOut}
                      >
                        <Icon path={LogOutIcon} size="16px" right="17px" />
                        Sair
                      </Typography>
                    </Box>
                  </Popover>
                </div>
              )}
            </PopupState>
          </Badge>
        </Toolbar>
      </AppBar>
      {children}
    </div>
  );
};

export default Navbar;
