import React, { createContext, useCallback, useState, useContext } from "react";
import api from "../services/api";

import { AxiosResponse } from "axios";

interface Activity {
  title: string;
  description: string;
  date: string;
}

interface ListActivityContextData {
  listActivity(): Promise<void>;
  hasErrors: boolean;
  showLoader: boolean;
  responseActivity: any;
}

const ListActivityContext = createContext<ListActivityContextData>(
  {} as ListActivityContextData
);

const ListActivityProvider: React.FC = ({ children }) => {
  const [errors, setErrors] = useState(false);
  const [loader, setLoader] = useState(false);
  const [responseActivityAPI, setResponseActivityAPI] = useState<string>("");
  const listActivity = useCallback(async () => {
    setLoader(true);
    setErrors(false);
   
  }, []);

  return (
    <ListActivityContext.Provider
      value={{
        hasErrors: errors,
        showLoader: loader,
        responseActivity: responseActivityAPI,
        listActivity,
      }}
    >
      {children}
    </ListActivityContext.Provider>
  );
};

function useListActivity(): ListActivityContextData {
  const context = useContext(ListActivityContext);

  if (!context) {
    throw new Error(
      "useListActivity must be used within an ListActivityProvider"
    );
  }

  return context;
}

export { ListActivityProvider, useListActivity };
