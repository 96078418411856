import React from 'react';

import { Container } from './styles';

interface TitleProps {
  weight?: any;
  fontFamily: string;
}


const Title: React.FC<TitleProps> = ({ children, weight, fontFamily, ...rest }) => {
  return (
    <Container 
      weight={
        weight 
          ? weight 
          : 500
      } 
      fontFamily='Roboto'
    >
      {children}
    </Container>
  );
}

export default Title;