import React from 'react';
import { Switch } from 'react-router-dom';

import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';
import ChangePassword from '../pages/ForgotPassword/ChangePassword';
import Student from '../pages/Student';
import Director from '../pages/Director';
import UpdatePassword from '../pages/UpdatePassword';
import EmployeeDashboard from '../pages/Employee';
import StudentAccount from '../pages/Student/MyAccount';
import RegisterStudent from '../pages/Director/Register/Student';
import RegisterEmployee from '../pages/Director/Register/Employee';
import RegisterEvent from '../pages/Director/Register/Activity/Event';
import RegisterCourse from '../pages/Director/Register/Activity/Course';
import StudentViewEvents from '../pages/Student/Subscribe/Event';
import StudentViewCourses from  '../pages/Student/Subscribe/Course';
import ViewEvents from '../pages/Director/Register/Subscribe/Event';
import ViewEventsAvailable from '../pages/Director/Register/Subscribe/Event/viewActivitys';
import ViewCourses from '../pages/Director/Register/Subscribe/Course';
import ViewCoursesAvailable from '../pages/Director/Register/Subscribe/Course/viewActivitys';
import DirectorAccount from '../pages/Director/MyAccount';
import EmployeeAccount from '../pages/Employee/MyAccount';
import ListStudentsNoSubscribers from '../pages/Director/Register/Subscribe/listStudentsNoSubscribers';

import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/forgotPassword" component={ForgotPassword} />
    <Route path="/changePassword/:token" component={ChangePassword} />
    <Route path="/student" component={Student} isPrivate />
    <Route path="/director" component={Director} isPrivate />
    <Route path="/employee" component={EmployeeDashboard} isPrivate />
    <Route path="/updatePassword" component={UpdatePassword} isPrivate />
    <Route path="/studentAccount" component={StudentAccount} isPrivate />
    <Route path="/registerStudent" component={RegisterStudent} isPrivate />
    <Route path="/registerEmployee" component={RegisterEmployee} isPrivate />
    <Route path="/registerEvent" component={RegisterEvent} isPrivate />
    <Route path="/registerCourse" component={RegisterCourse} isPrivate />
    <Route path="/studentViewEvents" component={StudentViewEvents} isPrivate />
    <Route path="/studentViewCourses" component={StudentViewCourses} isPrivate />
    <Route path="/viewEvents" component={ViewEvents} isPrivate />
    <Route path="/viewStudentsAvailable/:eventId" component={ViewEventsAvailable} isPrivate/>
    <Route path="/viewCourses" component={ViewCourses} isPrivate />
    <Route path="/viewCoursesAvailable/:courseId" component={ViewCoursesAvailable} isPrivate/>
    <Route path="/directorAccount" component={DirectorAccount} isPrivate/>
    <Route path="/employeeAccount" component={EmployeeAccount} isPrivate/>
    <Route path='/studentsNoSubscribers/:activityId' component={ListStudentsNoSubscribers} isPrivate />
  </Switch>
);

export default Routes;