import axios from 'axios';
import { getToken } from '../utils/getToken';
import refreshToken from '../utils/refreshToken';

const api = axios.create({
  baseURL: 'https://siecbackteste.herokuapp.com/',
});

// api.interceptors.response.use(
//   function(response) {
//       return response;
//   },
//   function(error) {
//     const statusCode = error.response?.status; 
//     const message = error.response?.statusText;
//     refreshToken(statusCode, message);
//   }
// );

// api.interceptors.request.use(async (config) => {
//   const token = getToken();
  
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }

//   return config;
// });

export default api;