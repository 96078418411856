import React from "react";

import { Container } from "./styles";
interface IconProps {
  path: string;
  size?: string;
  right?: string;
  left?: string;
  top?: string;
  bottom?: string;
}

const Icon: React.FC<IconProps> = ({
  children,
  path,
  size,
  right,
  left,
  top,
  bottom,
}) => {
  return (
    <Container
      src={path}
      style={{
        width: size ? size : "0px",
        marginRight: right ? right : "0px",
        marginLeft: left ? left : "0px",
        marginTop: top ? top : "0px",
        marginBottom: bottom ? bottom : "0px",
      }}
    />
  );
};

export default Icon;
