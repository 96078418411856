import React, { createContext, useCallback, useState, useContext } from "react";

interface StudentParams {
  id: number;
  name: string;
}

interface StudentContextData {
  updateStudentData(credentials: StudentParams): Promise<void>;
  id: number;
  name: string;
}

const StudentContext = createContext<StudentContextData>({} as StudentContextData);

const StudentProvider: React.FC = ({ children }) => {

  const [studentId, setStudentId] = useState<number>(0);
  const [studentName, setStudentName] = useState<string>("");

  const updateStudentData = useCallback(async ({ student_id, student_name }) => {
    setStudentId(student_id);
    setStudentName(student_name);
  }, []);

  return (
    <StudentContext.Provider value={{ updateStudentData, id: studentId, name: studentName }}>
      {children}
    </StudentContext.Provider>
  );
};

function useStudent(): StudentContextData {
  const context = useContext(StudentContext);

  if (!context) {
    throw new Error("useHistoryRoutes must be used within an HistoryRoutesProvider");
  }

  return context;
}

export { StudentProvider, useStudent };
