import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import api from "../../../../services/api";

import MaterialTable from "material-table";

import Modal from "../../../Modal";

import IDataTableProps from "../../interface";

import appColors from "../../../../shared/app_colors";

import IStudent from "../../../../models/StudentaInterface";

import IconHelper from "../../../../assets/icons/icon_help_outline.svg";
import { useRequestModal } from "../../../../hooks/requestModal";
import { useSubscribeActivity } from "../../../../hooks/subscribeAcitivity";
import { showToastr } from "../../../Toastr";
import { toast } from "react-toastify";
interface ReceiveParams {
  eventId: any;
}

const DataTableListActivitys: React.FC<IDataTableProps> = ({
  activityType,
  action,
  methods,
  ...rest
}) => {
  const [students, setStudents] = useState<IStudent[]>([]);
  const [studentId, setStudentId] = useState<any>();
  const [nameStudent, setNameStudent] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [noSubscribers, setNoSubscribers] = useState<boolean>(false);

  const { requestModal, closeModal } = useRequestModal();
  const { showLoader } = useSubscribeActivity();

  const history = useHistory();

  const { eventId } = useParams<ReceiveParams>();

  useEffect(() => {
    setLoader(true);
    api
      .get("/subscribe", {
        params: {
          activities_id: eventId,
        },
        headers: {
          "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
        }
      })
      .then((response) => {
        setLoader(false);
        if (response.data.length > 0) {
          setStudents(response.data);
        } else {
          setNoSubscribers(true);
        }
      });
  }, []);

  async function confirmDeleteSubscribe(subscribeID: any) {
    await api({
      method: "delete", 
      url: `subscribe/${subscribeID}`, 
      headers: {
        "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
      }
    }).then((response) => {
      showToastr(
        `Aluno removido com sucesso da atividade`, 
        'success',
        toast.POSITION.BOTTOM_RIGHT
      );

      // eslint-disable-next-line no-restricted-globals
      location.reload();
    })
  }

  async function confirmSubscribe() {
    await api({
      method: "post",
      url: `subscribe`,
      data: {
        activities_id: eventId,
        students_id: studentId
      },
      headers: {
        "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
      }
    })
    .then((response) => {
      showToastr(
        `Parabéns! o aluno teste foi inscrito ${nameStudent} no ${activityType === 'event' ? 'evento' : 'curso'} com sucesso`, 
        'success',
        toast.POSITION.BOTTOM_RIGHT
      );
      if (activityType === 'event') {
        history.push('/viewEvents')
      } else {
        history.push('/viewCourses');
      }
    }).catch((err) => {
      if(err.response.status === 403) {
        showToastr(
          `Infelizmente esse ${activityType === 'event' ? 'evento' : 'curso'} já está com as inscrições encerradas!`, 
          'error',
          toast.POSITION.BOTTOM_RIGHT
        );
      }else if(err.response.status === 400) {
        showToastr(
          `Infelizmente o aluno ${nameStudent} já está inscrito nesse ${activityType === 'event' ? 'evento' : 'curso'}`, 
          'error',
          toast.POSITION.BOTTOM_RIGHT
        );
      } 
    })

    closeModal();
  }

  return (
    <>
      <Modal
        icon={IconHelper}
        isLoading={showLoader}
        message={`
          Você deseja inscrever o aluno ${nameStudent} no ${
            activityType === "event" ? "evento" : "curso"
          }?
        `}
        buttonLeftMessage="VOLTAR"
        buttonRightMessage="CONFIRMAR INSCRIÇÃO"
        actions={() => confirmSubscribe()}
      />
      <MaterialTable
        title={"Alunos cadastrados no sistema"}
        onRowClick={async (event, rowData) => {
          history.push(`/viewStudentsAvailable/`)
        }}
        localization={{
          body: {
            emptyDataSourceMessage: noSubscribers 
            ? 'Nenhum aluno inscrito nessa atividade até o momento'
            : "Carregando...",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "linhas",
            labelRowsPerPage: "libhas por página:",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
          },
          toolbar: {
            searchTooltip: "Pesquisar evento",
            searchPlaceholder: "Pesquisar",
            exportAriaLabel: "Exportar",
            exportTitle: "Exportar",
            exportCSVName: "Exportar para EXCEL",
            exportPDFName: "Exportar para PDF",
          },
        }}
        columns={[
          { title: "Aluno", field: "nameStudent" },
          { title: "RA", field: "raStudent" },
          { title: "Classe", field: "classStudents" },
          { title: "Semestre", field: "semesterStudents" },
        ]}
        data={students}
        actions={[
          (rowData: any) => ({
            icon: "remove_circle",
            tooltip: "Remova um aluno nessa atividade",
            iconProps: { style: { color: appColors.siecStandardRed } },
            onClick: (event, rowData: any) => confirmDeleteSubscribe(rowData.id),
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          exportButton: true,
          exportFileName:
            activityType === "event"
              ? "EVENTOS DISPONÍVEIS"
              : "CURSOS DISPONÍVEIS",
        }}
        style={{ width: "100%" }}
      />
    </>
  );
};

export default DataTableListActivitys;
