/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Footer from "../../components/Footer";
import Icon from "../../components/Icon";
import Navbar from "../../components/Navbar";
import Button from "../../components/Button";

import CourseCardIcon from "../../assets/icons/icon_course.svg";
import EventCardIcon from "../../assets/icons/icon_event.svg";

import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
    display: "flex",
    overflow: "hidden",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#0064b7",

    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#0091ea",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
}));

export default function Student() {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Navbar permission="student">
        <main
          className={classes.content}
          style={{
            width: "10000px",
            backgroundColor: "#fafafa",
            overflowX: "hidden",
          }}
        >
          <div className={classes.appBarSpacer} />
          <div>
            {/* <Breadcrumb /> */}
            <h1
              style={{
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginLeft: "75px",
                marginTop: "25px",
              }}
            >
              Acesso Rápido
            </h1>
            <p style={{ marginLeft: "73px", fontSize: "12px" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={5}>
                <div
                  style={{
                    maxWidth: "400px",
                    height: "350px",
                    backgroundColor: "#FFF",
                    marginLeft: "75px",
                    border: "1px solid #eee",
                    borderRadius: "4px",
                    marginTop: "68px",
                  }}
                >
                  <Icon
                    path={CourseCardIcon}
                    size="250px"
                    left="32px"
                    top="25px"
                    right="75px"
                  />
                  <h2
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "30px",
                    }}
                  >
                    Cursos Disponíveis
                  </h2>
                  <p
                    style={{
                      fontFamily: "Roboto",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "30px",
                      marginTop: "3px",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <Button
                    type="button"
                    theme="primary"
                    hidden={false}
                    display="flex"
                    align="center"
                    justify="center"
                    left="27px"
                    top="15px"
                  >
                    <Link
                      to="/studentViewCourses"
                      style={{
                        textDecoration: "none",
                        color: "#FFF",
                      }}
                    >
                      ACESSAR
                    </Link>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    maxWidth: "400px",
                    height: "350px",
                    backgroundColor: "#FFF",
                    marginLeft: "75px",
                    border: "1px solid #eee",
                    borderRadius: "4px",
                    marginTop: "68px",
                  }}
                >
                  <Icon
                    path={EventCardIcon}
                    size="200px"
                    left="32px"
                    top="25px"
                    right="75px"
                  />
                  <h2
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: "30px",
                    }}
                  >
                    Eventos Disponíveis
                  </h2>
                  <p
                    style={{
                      fontFamily: "Roboto",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "30px",
                      marginTop: "3px",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <Button
                    type="button"
                    theme="primary"
                    hidden={false}
                    display="flex"
                    align="center"
                    justify="center"
                    left="27px"
                    top="15px"
                  >
                    <Link
                      to="/studentViewEvents"
                      style={{
                        textDecoration: "none",
                        color: "#FFF",
                      }}
                    >
                      ACESSAR
                    </Link>
                  </Button>
                </div>
              </Grid>
            </Grid>

            <Footer />
          </div>
        </main>
      </Navbar>
    </div>
  );
}
