/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useCallback, FormEvent } from "react";
import { useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { toast } from "react-toastify";
import { showToastr } from "../../../../components/Toastr";
import { useAuth } from "../../../../hooks/auth";

import { CircularProgress, Grid, TextField } from "@material-ui/core";

import Button from "../../../../components/Button";
import Card from "../../../../components/Card";
import Navbar from "../../../../components/Navbar";
import api from "../../../../services/api";
import { AxiosResponse } from "axios";
import BackRouter from "../../../../components/BackRouter";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
    display: "flex",
    overflow: "hidden",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#0064b7",

    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#0091ea",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
}));

const StudentRegister: React.FC = () => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [ra, setRa] = useState("");
  const [semester, setSemester] = useState(0);
  const [studentClass, setStudentClass] = useState("");

  const [formError, setFormError] = useState<boolean>(false);

  const [loader, setLoader] = useState<boolean>(false);

  const { user } = useAuth();

  const classes = useStyles();

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const data = {
        name,
        username,
        password,
        email,
        ra,
        semester,
        studentClass,
      };

      try {
        setLoader(true);
        setFormError(false);
        await api({
          method: "post",
          url: "user",
          data: {
            name: data.name,
            username: data.username,
            password: data.password,
            email: data.email,
            role_id: 3,
            organization_id: user.organization_id,
          },
          headers: {
            "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
          }
        }).then((response: AxiosResponse) => {
          api({
            method: "post",
            url: "student",
            data: {
              ra: data.ra,
              semester: data.semester,
              classStudent: data.studentClass,
              user_id: response.data.id,
              birth_date: data.password,
            },
            headers: {
              "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
            }
          })
            .then((response: AxiosResponse) => {
              setLoader(false);
              showToastr(
                "O aluno foi cadastrado com sucesso na plataforma!",
                "success",
                toast.POSITION.BOTTOM_RIGHT
              );
            })
            .catch((err) => {
              setLoader(false);
              console.log(err.response.data);
              if (err.response.status === 401) {
                showToastr(
                  "Você não tem a permissão para cadastrar alunos na plataforma! Contate a equipe de suporte",
                  "error",
                  toast.POSITION.BOTTOM_RIGHT
                );
              } else if (err.response.status === 400) {
                showToastr(
                  "Usuário ou email informado já pertence a outro aluno, tente novamente!",
                  "error",
                  toast.POSITION.BOTTOM_RIGHT
                );
              } else {
                showToastr(
                  "Não foi possível realizar o cadastro do aluno na plataforma! Tente novamente mais tarde!",
                  "error",
                  toast.POSITION.BOTTOM_RIGHT
                );
              }
            });
        });
      } catch (err) {
        setLoader(false);
        if (err.response.status === 400) {
          setFormError(true);
          showToastr(
            "Usuário ou email informado já pertence a outro aluno, tente novamente!",
            "error",
            toast.POSITION.BOTTOM_RIGHT
          );  
        }
      }
    },
    [
      email,
      name,
      password,
      ra,
      semester,
      studentClass,
      user.organization_id,
      username,
    ]
  );

  return (
    <Navbar permission="director">
      <main
        className={classes.content}
        style={{ overflowX: "hidden", height: "180vh" }}
      >
        <form onSubmit={handleSubmit}>
          <div className={classes.appBarSpacer} />
          <BackRouter />
          <h1
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: "75px",
              marginTop: "56px",
            }}
          >
            Adicionar Aluno
          </h1>
          <p style={{ marginLeft: "73px", fontSize: "12px" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <Card>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "70px",
              }}
            >
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Nome"
                  placeholder="Informe o nome do aluno"
                  helperText="Exemplo: Maria Júlia"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    minWidth: "300px",
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Usuário"
                  error={formError}
                  placeholder="Informe o usuário de acesso do aluno"
                  helperText="Exemplo: nome.sobrenome"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    minWidth: "300px",
                  }}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Data de nascimento"
                  placeholder="Informe a data de nascimento do aluno"
                  helperText="Exemplo: 22/04/2002"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "70px",
              }}
            >
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                <TextField
                  required
                  id="outlined-required"
                  label="E-mail"
                  error={formError}
                  placeholder="Informe o e-mail do aluno"
                  helperText="Exemplo: 0000000000@edu.sp.gov.br"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    minWidth: "300px",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                <TextField
                  required
                  id="outlined-required"
                  label="RA (Registro do Aluno)"
                  placeholder="Informe o RA do aluno"
                  helperText="Exemplo: 000.000.000 - 0"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    minWidth: "300px",
                  }}
                  value={ra}
                  onChange={(e) => setRa(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Semestre"
                  placeholder="Informe o semestre do aluno"
                  helperText="Exemplo: 1º semestre"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    minWidth: "300px",
                  }}
                  value={semester}
                  onChange={(e) => setSemester(Number(e.target.value))}
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "70px",
              }}
            >
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Classe"
                  placeholder="Informe a classe do aluno"
                  helperText="Exemplo: 3º ANO B"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    minWidth: "300px",
                  }}
                  value={studentClass}
                  onChange={(e) => setStudentClass(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                <TextField
                  id="outlined-basic"
                  type="password"
                  // error={!!hasErrors}
                  label="Senha"
                  variant="outlined"
                  // disabled={showLoader}
                  // value={password}
                  // onChange={(e) => setPassword(e.target.value)}
                  style={{
                    minWidth: "300px",
                    display: "none",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                <TextField
                  id="outlined-basic"
                  type="password"
                  // error={!!hasErrors}
                  label="Senha"
                  variant="outlined"
                  // disabled={showLoader}
                  // value={password}
                  // onChange={(e) => setPassword(e.target.value)}
                  style={{
                    minWidth: "300px",
                    display: "none",
                  }}
                />
              </Grid>
            </Grid>
          </Card>
          <div
            id="buttons"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              maxWidth: "1155px",
              marginTop: "37px",
            }}
          >
            {loader ? (
              <CircularProgress />
            ) : (
              <Button type="submit" theme="success" hidden={false}>
                SALVAR ALUNO
              </Button>
            )}
          </div>
        </form>
      </main>
    </Navbar>
  );
};

export default StudentRegister;
