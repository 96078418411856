import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    border-radius: 50%;
    height: 40px;
    background-color: #0064b7;
    align-items: center;
    justify-content: center;
    margin-left: 13px;
    width: 40px;
    cursor: pointer;

    p {
      font-family: 'Roboto';
      font-size: 23px;
      color: #FFF;
    }
`;

export const Username = styled.p`
  font-family: 'Roboto';
  display: flex;
  align-items: center;
  margin-top: -43px;
  justify-content: center;
  margin-left: 75px;
  white-space: pre-wrap;
  cursor: pointer;
`;

export const Email = styled.p`
  font-family: 'Roboto';
  margin-left: 10px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
