import { Grid } from "@material-ui/core";
import React from "react";
import BackRouter from "../../../../../components/BackRouter";
import DataTable from "../../../../../components/DataTable";
import DataTableCreateSubscribeEvent from "../../../../../components/DataTable/createSubscribers/Event";
import DataTableListActivitys from "../../../../../components/DataTable/createSubscribers/Event/listActivitysForSubscribe";
import Navbar from "../../../../../components/Navbar";

import useStyles from "../../../../../utils/useStyles";

const Event: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Navbar permission="director">
        <main
          className={classes.content}
          style={{ overflowX: "hidden", height: "250vh" }}
        >
          <form onSubmit={() => {}}>
            <div className={classes.appBarSpacer} />
            <BackRouter />
            <h1
              style={{
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginLeft: "75px",
                marginTop: "56px",
              }}
            >
              Realizar uma inscrição para um curso
            </h1>
            <p style={{ marginLeft: "73px", fontSize: "12px" }}>
              Selecione qual aluno deseja inscrever em um curso
            </p>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "70px",
                padding: "80px",
                marginTop: "-35px",
              }}
            >
              {/* <DataTableListActivitys activityType="event" action="subscribe" /> */}

              <DataTableCreateSubscribeEvent activityType="course" action="subscribe" />
            </Grid>
          </form>
        </main>{" "}
      </Navbar>
    </>
  );
};

export default Event;
