import React from "react";
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from "react-router-dom";

import { useAuth } from "../hooks/auth";

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect to={{ 
            pathname: isPrivate 
            ? "/" 
            : user.role_id === 1
            ? '/director'
            : user.role_id === 2
            ? '/director'
            : user.role_id === 3
            ? '/student'
            : '/employee',
          }} />
        );
      }}
    />
  );
};

export default Route;