import React from "react";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Navbar from "../../../../components/Navbar";
import DataTable from "../../../../components/DataTable";
import BackRouter from "../../../../components/BackRouter";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
    display: "flex",
    overflow: "hidden",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#0064b7",

    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#0091ea",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
}));

const StudentViewActivitys: React.FC = () => {

  const classes = useStyles();

  return (
    <>
      <Navbar permission="student">
        <main
          className={classes.content}
          style={{ overflowX: "hidden", height: "250vh" }}
        >
          <form onSubmit={() => {}}>
            <div className={classes.appBarSpacer} />
            <BackRouter />
            <h1
              style={{
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginLeft: "75px",
                marginTop: "56px",
              }}
            >
              Eventos Disponíveis
            </h1>
            <p style={{ marginLeft: "73px", fontSize: "12px" }}></p>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "70px",
                padding: "80px",
                marginTop: "-35px",
              }}
            >
              <DataTable
                activityType="event"
                action="subscribe"
              />
            </Grid>
          </form>
        </main>
      </Navbar>
    </>
  );
};

export default StudentViewActivitys;
