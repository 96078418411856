import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const Redirect = styled(Link)`
  text-decoration: none;
  color: #757575;
  font-family: 'Roboto';
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`;
