import React from "react";

import { BrowserRouter as Router } from "react-router-dom";

import GlobalStyle from "./styles/global";

import {
  AuthProvider,
  ForgotProvider,
  ChangePasswordProvider,
  UpdatePasswordProvider,
  ListActivityProvider,
  RequestModalProvider,
  SubscribeActivityProvider,
  ListActivitysSubscribeProvider,
  StudentProvider,
  OccupationStateProvider,
} from "./hooks";

import Routes from "./routes";

const App: React.FC = () => (
  <Router>
    <ForgotProvider>
      <ChangePasswordProvider>
        <AuthProvider>
          <UpdatePasswordProvider>
            <ListActivityProvider>
              <RequestModalProvider>
                <SubscribeActivityProvider>
                  <ListActivitysSubscribeProvider>
                    <StudentProvider>
                      <OccupationStateProvider>
                        <Routes />
                      </OccupationStateProvider>
                    </StudentProvider>
                  </ListActivitysSubscribeProvider>
                </SubscribeActivityProvider>
              </RequestModalProvider>
            </ListActivityProvider>
          </UpdatePasswordProvider>
        </AuthProvider>
      </ChangePasswordProvider>
    </ForgotProvider>
    <GlobalStyle />
  </Router>
);

export default App;
