import React from "react";

import { Container, Username, Email } from "./styles";

interface AvatarProps {
  alias: any;
  email: any;
  hidden: boolean;
}

const Avatar: React.FC<AvatarProps> = ({ alias, hidden, email, ...rest }) => {
  return (
    <>
      <Container {...rest} id="avatar-test">
        <p id="avatar-alias">{alias}</p>
      </Container>
      <Email 
        style={{ 
          display: hidden 
            ? 'flex' 
            : 'none'
        }}>
          {email}
        </Email>
    </>
  );
};

export default Avatar;
