/* eslint-disable no-restricted-globals */
import React, { createContext, useCallback, useState, useContext } from "react";
import api from "../services/api";

import { showToastr } from "../components/Toastr";
import { toast } from "react-toastify";

import { useAuth } from "./auth";

import { useHistory } from 'react-router-dom';
interface UpdatePasswordParams {
  studentId: any;
  password: string;
}

interface UpdatePasswordContextData {
  updatePassword(credentials: UpdatePasswordParams): Promise<void>;
  hasErrors: boolean;
  showLoader: boolean;
}

const UpdatePasswordContext = createContext<UpdatePasswordContextData>({} as UpdatePasswordContextData);

const UpdatePasswordProvider: React.FC = ({ children }) => {

  const [errors, setErrors] = useState(false);
  const [loader, setLoader] = useState(false);

  const { user } = useAuth();

  const history = useHistory();

  const updatePassword = useCallback(async ({ password, studentId }) => {
    setLoader(true);
    setErrors(false);
      await api({
        method: "put",
        url: `user/changePassword/${studentId}`,
        data: {
          password,
        },
        headers: {
          "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`,
        }
      }).then(() => {
        setLoader(false);
        showToastr(
          'Parabéns! Sua senha foi alterada com sucesso!', 
          'success',
          toast.POSITION.BOTTOM_RIGHT
        );
        localStorage.clear();
        location.reload();
      }).catch((err) => {
        setLoader(false);
        setErrors(true);
          showToastr(
            'Não foi possível alterar a sua senha! Tente novamente', 
            'error',
            toast.POSITION.BOTTOM_RIGHT
          );
      })
  }, []);


  return (
    <UpdatePasswordContext.Provider value={{ hasErrors: errors, showLoader: loader, updatePassword }}>
      {children}
    </UpdatePasswordContext.Provider>
  );
};

function useUpdatePassword(): UpdatePasswordContextData {
  const context = useContext(UpdatePasswordContext);

  if (!context) {
    throw new Error("useForgotPassword must be used within an ForgotPasswordProvider");
  }

  return context;
}

export { UpdatePasswordProvider, useUpdatePassword };
