import React from "react";
import { MDBContainer, MDBFooter } from "mdbreact";


export default function Footer() {

  return (
    <MDBFooter color="blue" className="font-small pt-4 mt-4">
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid style={{ marginTop: '110px', backgroundColor: '#eee', height: '40px' }}>
          <div style={{ height: '10px' }}></div>
          <span style={{marginTop: '-150px', marginLeft: '26px' }}>
            Copyright &copy; 
            {new Date().getFullYear()}-{new Date().getFullYear() + " "} 
            <a 
              href="/student" 
              style={{ 
                textDecoration: 'none', 
                color: '#1973bd',
                fontWeight: 'bold',
                fontFamily: 'Roboto' 
              }}> 
              Gestão de Educação Escolar 
            </a>
            . All rights reserved.
          </span>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}
