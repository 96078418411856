import React, { createContext, useCallback, useState, useContext } from "react";
import api from "../services/api";

import { AxiosResponse } from "axios";
import { showToastr } from "../components/Toastr";
import { toast } from "react-toastify";

interface SubscribeActivityParams {
  activities_id: number;
  user_id: number | undefined;
}

interface SubscribeActivityContextData {
  subscribeActivity(credentials: SubscribeActivityParams): Promise<void>;
  hasErrors: boolean;
  showLoader: boolean;
  success: boolean;
  student_id: number;
  date_of_birth: string;
  semester: number;
  student_ra: string;
  student_class: string;
}

const SubscribeActivityContext = createContext<SubscribeActivityContextData>(
  {} as SubscribeActivityContextData
);

const SubscribeActivityProvider: React.FC = ({ children }) => {
  const [errors, setErrors] = useState(false);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [studentId, setStudentId] = useState<number>(0);
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [semester, setSemester] = useState<number>(0);
  const [ra, setRa] = useState<string>("");
  const [studentClass, setStudentClass] = useState<string>("");

  const subscribeActivity = useCallback(async ({ activities_id, user_id }) => {
    setLoader(true);
    setErrors(false);
    setSuccess(false);
    await api({
      method: "get",
      url: `student`,
      params: {
        user_id,
      },
      headers: {
        "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
      }
    }).then((response: AxiosResponse) => {
      setStudentId(response.data[0]?.id);
      setDateOfBirth(response.data[0]?.birth_date);
      setSemester(response.data[0]?.semester);
      setRa(response.data[0]?.ra);
      setStudentClass(response.data[0]?.class);
        api({
          method: "post",
          url: "subscribe",
          data: {
            activities_id,
            students_id: response.data[0]?.id,
          },
          headers: {
            "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
          }
        })
          .then((response: AxiosResponse) => {
            setLoader(false);
            setSuccess(true);
            showToastr(
              "Parabéns! A sua inscrição foi confirmada com sucesso!",
              "success",
              toast.POSITION.BOTTOM_RIGHT
            );
          })
          .catch((err) => {
            setLoader(false);
            setErrors(true);
            setSuccess(false);
            if (err.response.status === 401) {
              showToastr(
                "Infelizmente não foi possível realizar a sua inscrição! Tente novamente!",
                "error",
                toast.POSITION.BOTTOM_RIGHT
              );
            } else if (err.response.status === 403) {
              showToastr(
                "Inscrições encerradas para essa atividade!",
                "error",
                toast.POSITION.BOTTOM_RIGHT
              );
            } else if (err.response.status === 400) {
              showToastr(
                "Você já realizou sua inscrição nessa atividade!",
                "error",
                toast.POSITION.BOTTOM_RIGHT
              );
            } else {
              showToastr(
                "Ocorreu um erro interno no servidor! Tente novamente mais tarde!",
                "error",
                toast.POSITION.BOTTOM_RIGHT
              );
            }
          });
    });
  }, []);

  return (
    <SubscribeActivityContext.Provider
      value={{
        hasErrors: errors,
        showLoader: loader,
        subscribeActivity,
        success,
        student_id: studentId,
        date_of_birth: dateOfBirth,
        semester: semester,
        student_class: studentClass,
        student_ra: ra,
      }}
    >
      {children}
    </SubscribeActivityContext.Provider>
  );
};

function useSubscribeActivity(): SubscribeActivityContextData {
  const context = useContext(SubscribeActivityContext);

  if (!context) {
    throw new Error(
      "useSubscribeActivity must be used within an SubscribeActivityProvider"
    );
  }

  return context;
}

export { SubscribeActivityProvider, useSubscribeActivity };
