import React from "react";
import { Grid } from "@material-ui/core";
import DataTable from "../../../../../components/DataTable";
import DataTableCreateSubscribeEvent from "../../../../../components/DataTable/createSubscribers/Event";
import Navbar from "../../../../../components/Navbar";

import useStyles from "../../../../../utils/useStyles";
import DataTableListActivitys from "../../../../../components/DataTable/createSubscribers/Event/listActivitysForSubscribe";
import BackRouter from "../../../../../components/BackRouter";
import Button from "../../../../../components/Button";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";

interface ReceiveParams {
  eventId: any;
}

const ViewEventsAvailable: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const { eventId } = useParams<ReceiveParams>();

  return (
    <>
      <Navbar permission="director">
        <main
          className={classes.content}
          style={{ overflowX: "hidden", height: "250vh" }}
        >
          <form onSubmit={() => {}}>
            <div className={classes.appBarSpacer} />
            <BackRouter />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginRight: "90px",
                marginTop: "63px",
                width: "185px",
                float: "right",
              }}
            >
              <Button
                isSubmit={false}
                theme="success"
                hidden={false}
              >
                <Link
                  to={`/studentsNoSubscribers/${eventId}`}
                  style={{
                    textDecoration: "none",
                    color: "#FFF",
                  }}
                >
                  ADICIONAR ALUNO
                </Link>
              </Button>
            </div>
            <div>
              <h1
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  fontSize: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginLeft: "75px",
                  marginTop: "56px",
                }}
              >
                Alunos cadastrados nessa atividade
              </h1>
              <p style={{ marginLeft: "73px", fontSize: "12px" }}>
                Agora escolha em qual evento você deseja inscrever o aluno{" "}
                {localStorage.getItem("@SIEC:studentId")}
              </p>
            </div>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "70px",
                padding: "80px",
                marginTop: "-35px",
              }}
            >
              <DataTableListActivitys activityType="event" action="subscribe" />
            </Grid>
          </form>
        </main>{" "}
      </Navbar>
    </>
  );
};

export default ViewEventsAvailable;
