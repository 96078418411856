import { CircularProgress, Grid } from "@material-ui/core";
import { AxiosResponse } from "axios";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import BackRouter from "../../../../../components/BackRouter";
import Modal from "../../../../../components/Modal";
import Navbar from "../../../../../components/Navbar";
import { showToastr } from "../../../../../components/Toastr";
import IStudent from "../../../../../models/StudentaInterface";
import api from "../../../../../services/api";
import appColors from "../../../../../shared/app_colors";

import useStyles from "../../../../../utils/useStyles";

interface UseParams {
  activityId: any;
}

const ListStudentsNoSubscribers: React.FC = () => {
  const [students, setStudents] = useState<IStudent[]>([]);
  const [responseEmpty, setResponseEmpty] = useState(false);

  const classes = useStyles();

  const history = useHistory();

  const { activityId } = useParams<UseParams>();

  useEffect(() => {
    api.get(
      "/subscribe/studentNotSubscribe", {
        params: {
          activities_id: activityId
        }, 
        headers: {
          "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
        }
      }
    ).then((response: AxiosResponse) => {
      if (response?.data.length > 0) {
        setStudents(response?.data);
      } else {
        setResponseEmpty(true);
      }
    });
  }, []);

  return (
    <>
      <Navbar permission="director">
        <main
          className={classes.content}
          style={{ overflowX: "hidden", height: "250vh" }}
        >
          <form onSubmit={() => {}}>
            <div className={classes.appBarSpacer} />
            <BackRouter />
            <h1
              style={{
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginLeft: "75px",
                marginTop: "56px",
              }}
            >
              Realize a inscrição de um aluno
            </h1>
            <p style={{ marginLeft: "73px", fontSize: "12px" }}>
              Selecione qual aluno deseja inscrever em um evento
            </p>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "70px",
                padding: "80px",
                marginTop: "-35px",
              }}
            >
              <Modal
                icon={<CircularProgress />}
                isLoading={true}
                message='Realizando a inscrição, aguarde...'
                buttonLeftMessage=""
                buttonRightMessage=""
                actions={() => console.log('')}
              />
              <MaterialTable
                title={"Escolha um aluno para inscrever na atividade"}
                onRowClick={(event, rowData: any) => {
                  api
                    .post("/subscribe", {
                      activities_id: activityId,
                      students_id: rowData.idStudent,
                    }, {
                      headers: {
                        "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
                      }
                    })
                    .then(() => {
                      showToastr(
                        `Parabéns! O aluno ${rowData.name} foi inscrito com sucesso na atividade`,
                        "success",
                        toast.POSITION.BOTTOM_RIGHT
                      );
                      history.goBack();
                    })
                    .catch((err) => {
                      showToastr(
                        `Ops! Falha ao inscrever o aluno nessa atividade, tente novamente!`,
                        "error",
                        toast.POSITION.BOTTOM_RIGHT
                      );
                    });
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: "Carregando alunos disponíveis",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    labelRowsSelect: "linhas",
                    labelRowsPerPage: "linhas por página:",
                    firstAriaLabel: "Primeira página",
                    firstTooltip: "Primeira página",
                    previousAriaLabel: "Página anterior",
                    previousTooltip: "Página anterior",
                    nextAriaLabel: "Próxima página",
                    nextTooltip: "Próxima página",
                    lastAriaLabel: "Última página",
                    lastTooltip: "Última página",
                  },
                  toolbar: {
                    searchTooltip: "Pesquisar curso",
                    searchPlaceholder: "Pesquisar",
                    exportAriaLabel: "Exportar",
                    exportTitle: "Exportar",
                    exportCSVName: "Exportar para EXCEL",
                    exportPDFName: "Exportar para PDF",
                  },
                  header: {
                    actions: "Ações",
                  },
                }}
                columns={[
                  { title: "Aluno", field: "nameStudent" },
                  { title: "RA", field: "raStudent" },
                  { title: "Semestre", field: "semesterStudents" },
                  { title: "Classe", field: "classStudents" },
                ]}
                data={students}
                actions={[
                  (rowData) => ({
                    icon: "add_circle",
                    tooltip: "Inscreva um aluno nessa atividade",
                    iconProps: { style: { color: appColors.siecMediumBlue } },
                    onClick: (event, rowData) => history.push('/viewStudentsAvailable'),
                    disabled: true,
                  }),
                ]}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportFileName: "ALUNOS CADASTRADOS NO SISTEMA",
                }}
                style={{ width: "100%" }}
              />
            </Grid>
          </form>
        </main>
      </Navbar>
    </>
  );
};

export default ListStudentsNoSubscribers;
