import React, { createContext, useCallback, useState, useContext } from "react";
import api from "../services/api";

import { AxiosResponse } from "axios";
import { showToastr } from "../components/Toastr";
import { toast } from "react-toastify";


interface ForgotPasswordParams {
  email: string;
}

interface ForgotPasswordContextData {
  forgotPassword(credentials: ForgotPasswordParams): Promise<void>;
  hasErrors: boolean;
  showLoader: boolean;
}

const ForgotPasswordContext = createContext<ForgotPasswordContextData>({} as ForgotPasswordContextData);

const ForgotPasswordProvider: React.FC = ({ children }) => {

  const [errors, setErrors] = useState(false);
  const [loader, setLoader] = useState(false);

  const forgotPassword = useCallback(async ({ email }) => {
    setLoader(true);
    setErrors(false);
      await api({
        method: "post",
        url: "user/forgotPassword",
        data: {
          email
        },
      }).then((response: AxiosResponse) => {
        setLoader(false);
        console.log(response.data);
        showToastr(
          'Verifique seu e-mail para dar continuação a redefinição da sua senha!', 
          'success',
          toast.POSITION.BOTTOM_RIGHT
        );
      }).catch((err) => {
        setLoader(false);
        setErrors(true);
        if(err.response.status === 404) {
          showToastr(
            'E=mail não encontrado! Tente novamente', 
            'error',
            toast.POSITION.BOTTOM_RIGHT
          );
        }
      })
  }, []);


  return (
    <ForgotPasswordContext.Provider value={{ hasErrors: errors, showLoader: loader, forgotPassword }}>
      {children}
    </ForgotPasswordContext.Provider>
  );
};

function useForgotPassword(): ForgotPasswordContextData {
  const context = useContext(ForgotPasswordContext);

  if (!context) {
    throw new Error("useForgotPassword must be used within an ForgotPasswordProvider");
  }

  return context;
}

export { ForgotPasswordProvider, useForgotPassword };
