import React, { FormEvent, useCallback, useState } from "react";

import {
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";

import Button from "../../components/Button";
import Card from "../../components/Card";
import { Redirect } from "../../styles/components/Input/styles";

import { useAuth } from "../../hooks/auth";

import { showToastr } from "../../components/Toastr/index";
import { toast } from "react-toastify";

import LoginIconOutline from "../../assets/icons/icon_login_outline.svg";
import PasswordIconOutline from "../../assets/icons/icon_password_outline.svg";

const SignIn: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { signIn, hasErrors, showLoader } = useAuth();

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const data = { username, password };
      try {
        console.log("2");
        signIn({
          username: data.username,
          password: data.password,
        });
        console.log("3");
      } catch (err) {
        showToastr(
          "Erro interno no servidor! Tente novamentee",
          "error",
          toast.POSITION.BOTTOM_RIGHT
        );
      }
    },
    [password, signIn, username]
  );

  return (
    <div style={{ marginTop: "200px" }}>
      <Card mr="350px" ml="350px" mt="-65px" padding="100px" radius="10px">
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={6} sm={12} style={{ maxWidth: "400px" }}>
              <TextField
                id="outlined-basic"
                fullWidth
                error={!!hasErrors}
                helperText={hasErrors && "Digite um nome de usuário válido"}
                type="text"
                label="Usuário"
                placeholder="Usuário"
                variant="outlined"
                disabled={showLoader}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={LoginIconOutline} alt="usuário" />
                    </InputAdornment>
                  ),
                }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={6} sm={12} style={{ maxWidth: "400px" }}>
              <TextField
                id="outlined-basic"
                fullWidth
                type="password"
                error={!!hasErrors}
                helperText={hasErrors && "Digite uma senha válida"}
                label="Senha"
                placeholder="Senha"
                variant="outlined"
                disabled={showLoader}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={PasswordIconOutline} alt="usuário" />
                    </InputAdornment>
                  ),
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Redirect to="/forgotPassword" style={{ marginRight: "145px" }}>
              Esqueceu a senha?
            </Redirect>

            {showLoader ? (
              <div style={{ paddingLeft: "45px", width: "85px" }}>
                <CircularProgress />
              </div>
            ) : (
              <Button type="submit" theme="primary" hidden={showLoader}>
                ENTRAR
              </Button>
            )}
          </Grid>
        </form>
      </Card>
    </div>
  );
};

export default SignIn;
