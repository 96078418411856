/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import api from "../../../../services/api";

import MaterialTable from "material-table";

import IDataTableProps from "../../interface";

import appColors from "../../../../shared/app_colors";

import IActivity from "../../../../models/activityInterface";

const DataTableCreateSubscribeEvent: React.FC<IDataTableProps> = ({
  activityType,
  action,
  methods,
  ...rest
}) => {
  const [activitys, setActivitys] = useState<IActivity[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [responseEmpty, setResponseEmpty] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem("@SIEC:studentId");
    setLoader(true);
    api
      .get("/activity", {
        params: {
          types_activities_id: activityType === "event" ? 1 : 2,
          open_subscribes: true,
        },
        headers: {
          "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
        }
      })
      .then((response) => {
        setLoader(false);
        if (response?.data.length > 0) {
          setActivitys(response?.data);
        } else {
          setResponseEmpty(true);
        }
      });
  }, []);

  return (
    <>
      <MaterialTable
        title={
          activityType === "event"
            ? "Eventos cadastrados no sistema"
            : "Cursos cadastrados no sistema"
        }
        onRowClick={(event, rowData: any) => {
          if (activityType === "event") {
            history.push(`/viewStudentsAvailable/${rowData.id}`);
          } else {
            history.push(`/viewCoursesAvailable/${rowData.id}`);
          }
        }}
        localization={{
          body: {
            emptyDataSourceMessage: responseEmpty 
            ? 'Não existem atividades com inscrições abertas no momento!'
            : activityType === 'event'
            ? 'Carregando eventos disponíveis...'
            : 'Carregando cursos disponíveis'
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "linhas",
            labelRowsPerPage: "linhas por página:",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
          },
          toolbar: {
            searchTooltip:
              activityType === "event" ? "Pesquisar evento" : "Pesquisar curso",
            searchPlaceholder: "Pesquisar",
            exportAriaLabel: "Exportar",
            exportTitle: "Exportar",
            exportCSVName: "Exportar para EXCEL",
            exportPDFName: "Exportar para PDF",
          },
          header: {
            actions: "Ações",
          },
        }}
        columns={[
          { title: "Título", field: "title" },
          { title: "Descrição", field: "description" },
          { title: "Data", field: "date" },
          { title: "Horário", field: "hours" },
          { title: "Local", field: "place" },
          { title: "Criador", field: "create_by_name" }
        ]}
        data={activitys}
        actions={[
          (rowData) => ({
            icon: "add_circle",
            tooltip:
              activityType === "event"
                ? "Inscreva um aluno nesse evento"
                : "Inscreva um aluno nesse curso",
            iconProps: { style: { color: appColors.siecMediumBlue } },
            onClick: (event, rowData) => console.log("SIEC"),
            disabled: true,
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          exportButton: true,
          exportFileName:
            activityType === "event"
              ? "EVENTOS CADASTRADOS NO SISTEMA"
              : "CURSOS CADASTRADOS NO SISTEMA",
        }}
        style={{ width: "100%" }}
      />
    </>
  );
};

export default DataTableCreateSubscribeEvent;
