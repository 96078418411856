import React, { useState, useCallback, FormEvent, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";

import { CircularProgress, Grid, TextField } from "@material-ui/core";

import Button from "../../../../../components/Button";
import Card from "../../../../../components/Card";
import Navbar from "../../../../../components/Navbar";

import { useAuth } from "../../../../../hooks/auth";
import api from "../../../../../services/api";
import { AxiosResponse } from "axios";
import { showToastr } from "../../../../../components/Toastr";
import { toast } from "react-toastify";
import BackRouter from "../../../../../components/BackRouter";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
    display: "flex",
    overflow: "hidden",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#0064b7",

    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#0091ea",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const RegisterCourse: React.FC = () => {
  const [titleCourse, setTitleCourse] = useState("");
  const [descriptionCourse, setDescriptionCourse] = useState("");
  const [dateCourse, setDateCourse] = useState("");
  const [scheduleCourse, setScheduleCourse] = useState("");
  const [durationCourse, setDurationCourse] = useState("");
  const [placeCourse, setPlaceCourse] = useState("");
  const [quantityStudents, setQuantityStudents] = useState(0);
  const [limitSubscribeDate, setLimitSubscribeDate] = useState("");

  const [titleInvalid, setTitleInvalid] = useState<boolean>(false);
  const [descriptionInvalid, setDescriptionInvalid] = useState<boolean>(false);
  const [dateInvalid, setDateInvalid] = useState<boolean>(false);
  const [hourInvalid, setHourInvalid] = useState<boolean>(false);
  const [durationInvalid, setDurationInvalid] = useState<boolean>(false);
  const [placeInvalid, setPlaceInvalid] = useState<boolean>(false);
  const [quantityInvalid, setQuantityInvalid] = useState<boolean>(false);
  const [dateLimitInvalid, setDateLimitInvalid] = useState<boolean>(false);
  const [
    limitSubscribeDateInvalid,
    setLimitSubscribeDateInvalid,
  ] = useState<boolean>(false);
  const [test, setTest] = useState<any>();

  const [loader, setLoader] = useState<boolean>(false);

  const { user } = useAuth();

  const classes = useStyles();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const data = {
        titleCourse,
        descriptionCourse,
        dateCourse,
        scheduleCourse,
        durationCourse,
        placeCourse,
        quantityStudents,
        limitSubscribeDate,
      };
        setLoader(true);
        try {
          await api({
            method: "post",
            url: "activity",
            data: {
              title: data.titleCourse,
              description: data.descriptionCourse,
              date: data.dateCourse,
              hours: data.scheduleCourse,
              duration: data.durationCourse,
              place: data.placeCourse,
              quantity: data.quantityStudents,
              organization_id: user.organization_id,
              types_activities_id: 2,
              deadline: limitSubscribeDate,
            },
            headers: {
              "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
            }
          })
            .then((response: AxiosResponse) => {
              setLoader(false);
              setTitleInvalid(false);
              setDescriptionInvalid(false);
              setDateInvalid(false);
              setHourInvalid(false);
              setDurationInvalid(false);
              setPlaceInvalid(false);
              setQuantityInvalid(false);
              setLimitSubscribeDateInvalid(false);
              setDateLimitInvalid(false);
  
              setTitleCourse("");
              setDescriptionCourse("");
              setDateCourse("");
              setScheduleCourse("");
              setDurationCourse("");
              setPlaceCourse("");
              setQuantityStudents(0);
              setLimitSubscribeDate("");
  
              showToastr(
                "O curso foi cadastrado com sucesso",
                "success",
                toast.POSITION.BOTTOM_RIGHT
              );
            })
        } catch(err) {
          console.log('aeeeee');
          if (err.response?.status === 401) {
            showToastr(
              "Você não tem a permissão para cadastrar cursos na plataforma! Contate a equipe de suporte",
              "error",
              toast.POSITION.BOTTOM_RIGHT
            );
          } else if (err.response?.status === 400) {
            setLoader(false);
            switch (err.response.data.error) {
              case "Adicione um título":
                setTitleInvalid(true);
                setDescriptionInvalid(false);
                setDateInvalid(false);
                setHourInvalid(false);
                setDurationInvalid(false);
                setPlaceInvalid(false);
                setQuantityInvalid(false);
                showToastr(
                  "Informe um título válido para o curso",
                  "error",
                  toast.POSITION.BOTTOM_RIGHT
                );
                break;
              case "Adicione uma descrição":
                setDescriptionInvalid(true);
                setTitleInvalid(false);
                setDateInvalid(false);
                setHourInvalid(false);
                setDurationInvalid(false);
                setPlaceInvalid(false);
                setQuantityInvalid(false);
                showToastr(
                  "Informe uma descrição válida para o curso",
                  "error",
                  toast.POSITION.BOTTOM_RIGHT
                );
                break;
              case "Adicione uma data":
                setDateInvalid(true);
                setTitleInvalid(false);
                setDescriptionInvalid(false);
                setHourInvalid(false);
                setDurationInvalid(false);
                setPlaceInvalid(false);
                setQuantityInvalid(false);
                showToastr(
                  "Informe uma data válida para o curso",
                  "error",
                  toast.POSITION.BOTTOM_RIGHT
                );
                break;
              case "Adicione uma data limite":
                setLimitSubscribeDateInvalid(true);
                setTitleInvalid(false);
                setDescriptionInvalid(false);
                setHourInvalid(false);
                setDurationInvalid(false);
                setPlaceInvalid(false);
                setQuantityInvalid(false);
                showToastr(
                  "Informe uma data limite válida para inscrições desse curso",
                  "error",
                  toast.POSITION.BOTTOM_RIGHT
                );
                break;
              case "Data tem que ser maior que hoje":
                setLimitSubscribeDateInvalid(false);
                setDateInvalid(true);
                setTitleInvalid(false);
                setDescriptionInvalid(false);
                setHourInvalid(false);
                setDurationInvalid(false);
                setPlaceInvalid(false);
                setQuantityInvalid(false);
                showToastr(
                  "Informe uma data válida para cadastrar esse curso",
                  "error",
                  toast.POSITION.BOTTOM_RIGHT
                );
                break;
              case "Data limite tem que maior que hoje":
                setLimitSubscribeDateInvalid(true);
                setDateInvalid(false);
                setTitleInvalid(false);
                setDescriptionInvalid(false);
                setHourInvalid(false);
                setDurationInvalid(false);
                setPlaceInvalid(false);
                setQuantityInvalid(false);
                showToastr(
                  "Informe uma data limite válida para cadastrar esse curso",
                  "error",
                  toast.POSITION.BOTTOM_RIGHT
                );
                break;
                case "Data limite tem que ser menor que data da atividade":
                  setLimitSubscribeDateInvalid(true);
                  setDateInvalid(false);
                  setTitleInvalid(false);
                  setDescriptionInvalid(false);
                  setHourInvalid(false);
                  setDurationInvalid(false);
                  setPlaceInvalid(false);
                  setQuantityInvalid(false);
                  showToastr(
                    "Informe uma data limite que ocorra antes da data do curso",
                    "error",
                    toast.POSITION.BOTTOM_RIGHT
                  );
                  break;
              default:
                showToastr(
                  "Erro interno no servidor, tente novamente!",
                  "error",
                  toast.POSITION.BOTTOM_RIGHT
                );
                break;
            }
            return;
          } else {
            showToastr(
              "Erro interno no servidor",
              "error",
              toast.POSITION.BOTTOM_RIGHT
            );
          }
        }
    },
    [
      dateCourse,
      descriptionCourse,
      durationCourse,
      limitSubscribeDate,
      placeCourse,
      quantityStudents,
      scheduleCourse,
      titleCourse,
      user.organization_id,
    ]
  );

  return (
    <Navbar permission="director">
      <main
        className={classes.content}
        style={{ overflowX: "hidden", height: "150vh" }}
      >
        <form onSubmit={handleSubmit}>
          <div className={classes.appBarSpacer} />
          <BackRouter />
          <h1
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: "75px",
              marginTop: "56px",
            }}
          >
            {test}
          </h1>
          <p style={{ marginLeft: "73px", fontSize: "12px" }}>
            Cadastre um curso para que os alunos possam realiza suas inscrições.
          </p>
          <Card>
            <Grid container spacing={3} style={{ maxWidth: "1114px" }}>
              <Grid item xs={12} sm={6} style={{ maxWidth: "522px" }}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  error={titleInvalid}
                  helperText={
                    titleInvalid && "Informe um título válido para o curso"
                  }
                  label="Título"
                  placeholder="Informe um título para o curso"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={titleCourse}
                  onChange={(e) => setTitleCourse(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ maxWidth: "540px" }}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  error={descriptionInvalid}
                  helperText={
                    descriptionInvalid &&
                    "Informe uma descrição válida para o curso"
                  }
                  label="Descrição"
                  placeholder="Informe uma breve descrição para o curso"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={descriptionCourse}
                  onChange={(e) => setDescriptionCourse(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ maxWidth: "364px" }}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  error={dateInvalid}
                  helperText={
                    dateInvalid && "Informe uma data válida para o curso"
                  }
                  label="Data do Curso"
                  placeholder="Informe uma breve descrição para o curso"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dateCourse}
                  onChange={(e) => setDateCourse(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ maxWidth: "290px" }}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  error={hourInvalid}
                  helperText={
                    hourInvalid && "Informe um horário válido para o curso"
                  }
                  label="Horário"
                  placeholder="Informe uma horário para o curso"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={scheduleCourse}
                  onChange={(e) => setScheduleCourse(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ maxWidth: "356px" }}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  error={durationInvalid}
                  helperText={
                    durationInvalid && "Informe uma duração válida para o curso"
                  }
                  label="Duração"
                  placeholder="Informe a duração do curso"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={durationCourse}
                  onChange={(e) => setDurationCourse(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ maxWidth: "365px" }}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  error={placeInvalid}
                  helperText={
                    placeInvalid && "Informe um local válido para o curso"
                  }
                  label="Local"
                  placeholder="Informe o local do curso"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={placeCourse}
                  onChange={(e) => setPlaceCourse(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ maxWidth: "356px" }}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  error={quantityInvalid}
                  helperText={
                    quantityInvalid &&
                    "Informe uma quantidade de alunos válida para o curso"
                  }
                  label="Quantidade de Alunos"
                  placeholder="Informe a quantidade de alunos"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="number"
                  value={quantityStudents}
                  onChange={(e) => setQuantityStudents(Number(e.target.value))}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ maxWidth: "295px" }}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  error={limitSubscribeDateInvalid}
                  helperText={
                    limitSubscribeDateInvalid &&
                    "Informe uma data limite válida para realizar as inscrições nesse curso"
                  }
                  label="Data limite para inscrições"
                  placeholder="Informe uma data limite para realizar as inscrições"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  value={limitSubscribeDate}
                  onChange={(e) => setLimitSubscribeDate(e.target.value)}

                />
              </Grid>
            </Grid>
          </Card>
          <div
            id="buttons"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              maxWidth: "1155px",
              marginTop: "37px",
            }}
          >
            {loader ? (
              <CircularProgress />
            ) : (
              <Button type="submit" theme="success" hidden={false}>
                SALVAR CURSO
              </Button>
            )}
          </div>
        </form>
      </main>
    </Navbar>
  );
};
export default RegisterCourse;
