import React, { useState, useCallback, FormEvent } from "react";

import { MdKeyboardBackspace } from "react-icons/md";

import { CircularProgress, Grid, InputAdornment, TextField } from "@material-ui/core";

import Button from "../../components/Button";

import { Redirect } from "../../styles/components/Input/styles";

import { useForgotPassword } from "../../hooks/forgotPassword";
import { toast } from "react-toastify";
import { showToastr } from "../../components/Toastr";
import Card from "../../components/Card";

import EmailIconOutline from "../../assets/icons/icon_email_outline.svg";

const ForgotPassword: React.FC = () => {
  const [isFilled, setIsFilled] = useState(false);
  const [email, setEmail] = useState("");

  const { forgotPassword, hasErrors, showLoader } = useForgotPassword();

  const handleInputFilled = useCallback(() => {
    if (email !== "") {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [email]);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const data = { email };
      try {
        forgotPassword({
          email: data.email,
        });
      } catch (err) {
        showToastr(
          "Erro interno no servidor! Tente novamentee",
          "error",
          toast.POSITION.BOTTOM_RIGHT
        );
      }
    },
    [email, forgotPassword]
  );

  return (
    <div style={{ marginTop: "200px" }}>
      <Card mr="350px" ml="350px" mt="-65px" padding="100px" radius="10px">
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={6} sm={12} style={{ maxWidth: "400px" }}>
              <TextField
                id="outlined-basic"
                fullWidth
                type="text"
                label="E-mail"
                placeholder="E-mail"
                variant="outlined"
                error={!!hasErrors}
                helperText={hasErrors && 'Digite um endereço de e-mail válido'}
                disabled={showLoader}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={EmailIconOutline} alt="email" />
                    </InputAdornment>
                  ),
                }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value.trim());
                }}
                onBlur={handleInputFilled}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
          >
            <Redirect to="/" style={{ marginLeft: '20px' }}>
              <MdKeyboardBackspace />
              VOLTAR
            </Redirect>
            {showLoader ? (
              <CircularProgress />
            ) : (
              <Button
                type="button"
                theme={isFilled ? "success" : ""}
                hidden={isFilled ? false : true}
                right='15px'
              >
                RECUPERAR SENHA
              </Button>
            )}
          </Grid>
        </form>
      </Card>
    </div>
  );
};

export default ForgotPassword;
