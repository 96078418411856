import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";

import { MdKeyboardArrowDown as ExpandOption } from "react-icons/md";
import TableComponent from "../Table";
import DataTable from "../DataTable";
import DataTableSubscribers from "../DataTable/ListSubscribers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);

interface AccordionProps {
  title: string;
  quantityItens: string;
  activityType: string;
  iconType: string;
  action: string;
}

const AccordionComponent: React.FC<AccordionProps> = ({
  title,
  quantityItens,
  activityType,
  action,
  iconType,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion data-testid="parent">
        <AccordionSummary
          expandIcon={<ExpandOption />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            {title} {`(${quantityItens})`}
          </Typography>
        </AccordionSummary>
        <DataTableSubscribers activityType={activityType} action={action} />
      </Accordion>
    </div>
  );
};

export default AccordionComponent;
