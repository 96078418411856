import React, { useState, useCallback, FormEvent } from "react";

import { CircularProgress, Grid, TextField } from "@material-ui/core";

import Button from "../../components/Button";
import Modal from '../../components/Modal';

import { useUpdatePassword } from "../../hooks/updatePasswordForPrimaryAccess";
import { useAuth } from "../../hooks/auth";
import { toast } from "react-toastify";
import { showToastr } from "../../components/Toastr";

const UpdatePassword: React.FC = () => {
  const [isFilled, setIsFilled] = useState(false);
  const [password, setPassword] = useState("");
  const [passConfirm, setPassConfirm] = useState("");
  const [passInvalid, setPassInvalid] = useState(false);

  const { updatePassword, showLoader } = useUpdatePassword();
  const { user } = useAuth();
  
  const handleInputFilled = useCallback(() => {
    if (password !== "" && passConfirm !== "") {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [password, passConfirm]);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setPassInvalid(false);
      const data = { password };
      if (passConfirm !== password) {
        setPassInvalid(true);
        showToastr(
          "As senhas não conferem! Tente novamente!",
          "error",
          toast.POSITION.BOTTOM_RIGHT
        );
      } else {
        try {
          updatePassword({
            password: data.password,
            studentId: user?.id
          })
        } catch (err) {
          showToastr(
            "Erro interno no servidor! Tente novamente",
            "error",
            toast.POSITION.BOTTOM_RIGHT
          );
        }
      }
    },
    [password, passConfirm, updatePassword, user?.id]
  );

  return (
    <div style={{ marginTop: "200px" }}>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={3}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              fullWidth
              type="password"
              label="Criar nova senha"
              variant="outlined"
              disabled={showLoader}
              error={passInvalid}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value.trim());
              }}
              onBlur={handleInputFilled}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-basic"
              fullWidth
              type="password"
              label="Digite a senha novamente"
              variant="outlined"
              error={passInvalid}
              disabled={showLoader}
              value={passConfirm}
              onChange={(e) => {
                setPassConfirm(e.target.value);
              }}
              onBlur={handleInputFilled}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            marginTop: "30px",
            maxWidth: "840px",
            float: "right"
          }}
        >
          {showLoader ? (
            <CircularProgress />
          ) : (
            <Button
              type="submit"
              theme={isFilled ? "success" : ""}
              hidden={isFilled ? false : true}
            >
              MUDAR A SENHA
            </Button>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default UpdatePassword;
