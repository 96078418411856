import React, { useState } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";

import Button from "../Button";

import { useRequestModal } from "../../hooks/requestModal";
import { CircularProgress } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

interface ModalProps {
  icon?: any;
  title?: string;
  message: string;
  buttonLeftMessage: string;
  buttonRightMessage: string;
  actions?: any;
  isLoading: boolean;
  open?: any;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Modal: React.FC<ModalProps> = ({
  icon,
  title,
  message,
  buttonLeftMessage,
  buttonRightMessage,
  actions,
  isLoading,
}) => {
  const { closeModal, modalOpen } = useRequestModal();

  return (
    <div>
      <Dialog
        onClose={() => closeModal()}
        aria-labelledby="customized-dialog-title"
        open={modalOpen}
      >
        {title && (
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => closeModal()}
          >
            {title}
          </DialogTitle>
        )}
        <DialogContent
          dividers
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {icon && (
            <img
              src={icon}
              alt="imagem"
              style={{
                marginTop: "41px",
              }}
            />
          )}
          <Typography
            gutterBottom
            style={{
              marginTop: "-50px",
              marginBottom: "-60px",
              padding: "71px",
              textAlign: "center",
              fontSize: "18px",
            }}
          >
            {message}
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: "20px" }}>
          <Button onPressed={() => closeModal()} theme="other" hidden={false}>
            {buttonLeftMessage}
          </Button>
          {isLoading ? (
            <div style={{ marginLeft: '80px' }}>
              <CircularProgress />
            </div>
          ) : (
            <Button
              onPressed={actions ? actions : () => closeModal()}
              theme="success"
              hidden={false}
            >
              {buttonRightMessage}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Modal;
