import React, { createContext, useCallback, useState, useContext } from "react";

import { IOccupationContextData } from '../models/occupationState';

const OccupationStateContext = createContext<IOccupationContextData>({} as IOccupationContextData);

const OccupationStateProvider: React.FC = ({ children }) => {

  const [occupationState, setOccupationState] = useState<string>('');

  const updateOccupationState = useCallback( async ({ value }) => {
    setOccupationState(value);
  }, []);

  return (
    <OccupationStateContext.Provider value={{ updateOccupationState, state: occupationState }}>
      {children}
    </OccupationStateContext.Provider>
  );
};

function useOccupationState(): IOccupationContextData {
  const context = useContext(OccupationStateContext);

  if (!context) {
    throw new Error('useOccupationState must be used withiin an OccupationStateProvider');
  }

  return context;
}

export { OccupationStateProvider, useOccupationState };