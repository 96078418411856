/* eslint-disable no-restricted-globals */
import React, { useState, useCallback, FormEvent } from "react";

import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";

import { toast } from "react-toastify";
import { showToastr } from "../../../../components/Toastr";

import { CircularProgress, Grid, TextField } from "@material-ui/core";

import Button from "../../../../components/Button";
import Card from "../../../../components/Card";
import Navbar from "../../../../components/Navbar";
import api from "../../../../services/api";
import { AxiosResponse } from "axios";

import { useOccupationState } from '../../../../hooks/occupationState';
import BackRouter from "../../../../components/BackRouter";

const drawerWidth = 240;

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      padding: 16,
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
    display: "flex",
    overflow: "hidden",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#0064b7",

    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#0091ea",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
}));

const EmployeeRegister: React.FC = () => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [occupation, setOccupation] = useState("");
  const [loader, setLoader] = useState<boolean>(false);

  const { state } = useOccupationState();

  const classes = useStyles();

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const data = {
        name,
        username,
        password,
        email,
        occupation,
      };

      try {
        setLoader(true);
        await api({
          method: "post",
          url: "user",
          data: {
            name: data.name,
            username: data.username,
            password: data.password,
            email: data.email,
          },
          headers: {
            "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
          }
        }).then((response: AxiosResponse) => {
          setLoader(false);
          setName('');
          setUsername('');
          setPassword('');
          setEmail('');
          showToastr(
            "O funcionário foi cadastrado com sucesso!",
            "success",
            toast.POSITION.BOTTOM_RIGHT
          );
        }).catch((err) => {
          setLoader(false);
          setName('');
          setUsername('');
          setPassword('');
          setEmail('');
          showToastr(
            "Falha ao cadastrar o funcionário tente novamente",
            "error",
            toast.POSITION.BOTTOM_RIGHT
          );
        })
      } catch (err) {
        setLoader(false);
        showToastr(
          "Erro interno no servidor! Tente novamentee",
          "error",
          toast.POSITION.BOTTOM_RIGHT
        );
      }
    },
    [email, name, occupation, password, username]
  );

  return (
    <Navbar permission="director">
      <main
        className={classes.content}
        style={{ overflowX: "hidden", height: "150vh" }}
      >
        <form onSubmit={handleSubmit}>
          <div className={classes.appBarSpacer} />
          <BackRouter />
          <h1
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: "75px",
              marginTop: "56px",
            }}
          >
            Adicionar Funcionário
          </h1>
          <p style={{ marginLeft: "73px", fontSize: "12px" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <Card>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "70px",
              }}
            >
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Nome"
                  placeholder="Informe o nome do funcionário"
                  helperText="Exemplo: Rosa Maria"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    minWidth: "300px",
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Usuário"
                  placeholder="Informe um usuário para o funcionário"
                  helperText="Exemplo: nome.sobrenome"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    minWidth: "300px",
                  }}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Senha"
                  placeholder="Informe uma senha para o funcionário"
                  helperText="Exemplo: 22/04/2002"
                  type="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "70px",
              }}
            >
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                <TextField
                  required
                  id="outlined-required"
                  label="E-mail"
                  placeholder="Informe o e-mail do funcionário"
                  helperText="Exemplo: 0000000000@edu.sp.gov.br"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    minWidth: "300px",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px", height: '75px' }}>
                <NativeSelect
                  id="demo-customized-select-native"
                  fullWidth
                  input={<BootstrapInput />}
                  value={state}
                  onChange={(e) => {
                    setOccupation(e.target.value);
                  }}
                  style={{ display: 'none' }}
                >
                  <option value={"selecione"}>SELECIONE A OCUPAÇÃO DO FUNCIONÁRIO</option>
                  <option value={'diretor'}>DIRETOR</option>
                  <option value={'funcionário'}>FUNCIONÁRIO</option>
                </NativeSelect>
              </Grid>
              <Grid item xs={12} sm={4} style={{ maxWidth: "320px" }}>
                <TextField
                  required
                  id="outlined-required"
                  label="E-mail"
                  placeholder="Informe o e-mail do funcionário"
                  helperText="Exemplo: 0000000000@edu.sp.gov.br"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    minWidth: "300px",
                    display: "none",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
          </Card>
          <div
            id="buttons"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              maxWidth: "1155px",
              marginTop: "37px",
            }}
          >
            {loader ? (
              <CircularProgress />
            ) : (
              <Button type="submit" theme="success" hidden={false}>
                SALVAR FUNCIONÁRIO
              </Button>
            )}
          </div>
        </form>
      </main>
    </Navbar>
  );
};

export default EmployeeRegister;
