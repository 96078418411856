import React, { createContext, useCallback, useState, useContext } from "react";

import Modal from "../components/Modal";

interface RequestModalParams {
  openModal: boolean;
  idActivity?: any | undefined;
}

interface RequestModalContextData {
  requestModal(parameters: RequestModalParams): Promise<void>;
  closeModal(): void;
  modalOpen: boolean;
  idActivity?: any | undefined;

}

const RequestModalContext = createContext<RequestModalContextData>(
  {} as RequestModalContextData
);

const RequestModalProvider: React.FC = ({ children }) => {
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [activityId, setActivityId] = useState();

  const requestModal = useCallback(
    async ({ idActivity }) => {
      setOpenStatus(true);
      setActivityId(idActivity);
    },
    []
  );

  const closeModal = useCallback(() => {
    setOpenStatus(false);
  }, []);

  return (
    <RequestModalContext.Provider
      value={{
        requestModal,
        closeModal,
        modalOpen: openStatus,
        idActivity: activityId
      }}
    >
      {children}
    </RequestModalContext.Provider>
  );
};

function useRequestModal(): RequestModalContextData {
  const context = useContext(RequestModalContext);

  if (!context) {
    throw new Error(
      "useRequestModal must be used within an RequestModalProvider"
    );
  }

  return context;
}

export { RequestModalProvider, useRequestModal };
