import React from "react";
import { Grid } from "@material-ui/core";
import DataTable from "../../../../../components/DataTable";
import DataTableCreateSubscribeEvent from "../../../../../components/DataTable/createSubscribers/Event";
import Navbar from "../../../../../components/Navbar";

import useStyles from '../../../../../utils/useStyles';
import DataTableListActivitys from "../../../../../components/DataTable/createSubscribers/Event/listActivitysForSubscribe";
import BackRouter from "../../../../../components/BackRouter";

const ViewEventsAvailable: React.FC = () => {

  const classes = useStyles();

  return (
    <>
      <Navbar permission="director">
        <main
          className={classes.content}
          style={{ overflowX: "hidden", height: "250vh" }}
        >
          <form onSubmit={() => {}}>
            <div className={classes.appBarSpacer} />
            <BackRouter />
            <h1
              style={{
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginLeft: "75px",
                marginTop: "56px",
              }}
            >
              Cursos disponíveis aqui
            </h1>
            <p style={{ marginLeft: "73px", fontSize: "12px" }}>
              Agora escolha em qual curso você deseja inscrever o aluno {localStorage.getItem('@SIEC:studentId')}
            </p>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "70px",
                padding: "80px",
                marginTop: "-35px",
              }}
            >
              <DataTableListActivitys activityType="course" action="subscribe" />
            </Grid>
          </form>
        </main>{" "}
      </Navbar>
    </>
  );
};

export default ViewEventsAvailable;
