import styled from 'styled-components';

export const Container = styled.div`
  background-color: #FFF;
  margin-top: 55px;
  margin-left: 60px;
  margin-right: 30px;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 56px;
`;
