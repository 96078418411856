import React, { useState, useEffect } from "react";

import api from "../../services/api";

import MaterialTable from "material-table";

import IDataTableProps from "./interface";

import appColors from "../../shared/app_colors";

import Modal from "../../components/Modal";

import { useAuth } from "../../hooks/auth";
import { useSubscribeActivity } from "../../hooks/subscribeAcitivity";
import { useRequestModal } from "../../hooks/requestModal";

import IconHelper from "../../assets/icons/icon_help_outline.svg";
import { formatDate } from "../../utils/formatDate";

interface ActivityProps {
  id: number;
  title: string;
  description: string;
  date: string;
  hours: string;
  place: string;
  types_activities_id: number;
}

const DataTable: React.FC<IDataTableProps> = ({
  activityType,
  action,
  methods,
  ...rest
}) => {
  const [activitys, setActivitys] = useState<ActivityProps[]>([]);
  const [activitySubscriber, setActivitySubscriber] = useState<ActivityProps[]>(
    []
  );
  const [activityId, setActivityId] = useState<any>(0);
  const [actitvityTitle, setActivityTitle] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);

  const { user } = useAuth();
  const { requestModal, closeModal } = useRequestModal();
  const { subscribeActivity, showLoader } = useSubscribeActivity();

  useEffect(() => {
    setLoader(true);
    api
      .get("/activity", {
        params: {
          types_activities_id: activityType === "event" ? 1 : 2,
        },
        headers: {
          "Authorization": `Basic ${localStorage.getItem('@SIEC:token')}`
        }
      })
      .then((response) => {
        setLoader(false);
        setActivitys(response.data);
      });
      console.log(activitys);
  }, []);

  async function confirmSubscribe() {
    await subscribeActivity({ activities_id: activityId, user_id: user.id });

    closeModal();
  }

  return (
    <>
      <Modal
        icon={IconHelper}
        isLoading={showLoader}
        message={`
          Você deseja se inscrever no ${
            activityType === "event" ? "evento" : "curso"
          } ${actitvityTitle}?
        `
        }
        buttonLeftMessage="VOLTAR"
        buttonRightMessage="CONFIRMAR INSCRIÇÃO"
        actions={() => confirmSubscribe()}
      />
      <MaterialTable
        title=""
        onRowClick={(event, rowData) => {
          requestModal({ openModal: true });
          setActivityId(rowData?.id);
          setActivityTitle(rowData?.title);
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "Carregando...",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "linhas",
            labelRowsPerPage: "libhas por página:",
            firstAriaLabel: "Primeira página",
            firstTooltip: "Primeira página",
            previousAriaLabel: "Página anterior",
            previousTooltip: "Página anterior",
            nextAriaLabel: "Próxima página",
            nextTooltip: "Próxima página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
          },
          toolbar: {
            searchTooltip: "Pesquisar evento",
            searchPlaceholder: "Pesquisar",
            exportAriaLabel: "Exportar",
            exportTitle: "Exportar",
            exportCSVName: "Exportar para EXCEL",
            exportPDFName: "Exportar para PDF",
          },
          header: {
            actions: "Ações",
          },
        }}
        columns={[
          { title: "Título", field: "title" },
          { title: "Descrição", field: "description" },
          { title: "Data", field: "date" },
          { title: "Local", field: "place" },
        ]}
        data={activitys}
        actions={[
          (rowData) => ({
            icon: "add_circle",
            tooltip: "Inscrever-se nesse evento",
            iconProps: { style: { color: appColors.siecMediumBlue } },
            onClick: (event, rowData) => console.log("SIEC"),
            disabled: true,
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          exportButton: true,
          exportFileName:
            activityType === "event"
              ? "EVENTOS DISPONÍVEIS"
              : "CURSOS DISPONÍVEIS",
        }}
        style={{ width: "100%" }}
      />
    </>
  );
};

export default DataTable;
