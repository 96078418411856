import React, { createContext, useCallback, useState, useContext } from "react";
import api from "../services/api";

import { AxiosResponse } from "axios";

interface ListActivitysSubscribeParams {
  user_id: number | undefined;
}

interface ListActivitysSubscribeContextData {
  listActivitysSubscribe(
    credentials: ListActivitysSubscribeParams
  ): Promise<void>;
  hasErrors: boolean;
  showLoader: boolean;
  success: boolean;
  userId: any;
  activitysSubscribe: Array<string>;
}

const ListActivitysSubscribeContext = createContext<ListActivitysSubscribeContextData>(
  {} as ListActivitysSubscribeContextData
);

const ListActivitysSubscribeProvider: React.FC = ({ children }) => {
  const [errors, setErrors] = useState(false);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [userId, setUserId] = useState<any>();
  const [activitysSubscribe, setActivitysSubscribe] = useState<Array<string>>([
    "",
  ]);

  const listActivitysSubscribe = useCallback(async ({ user_id }: any) => {
    setLoader(true);
    setErrors(false);
    setSuccess(false);
    await api({
      method: "get",
      url: "/student",
      params: {
        user_id,
      },
    }).then((response: AxiosResponse) => {
      api({
        method: "get",
        url: "/subscribe",
        params: {
          students_id: response.data[0].id,
        },
      })
    });
  }, []);

  return (
    <ListActivitysSubscribeContext.Provider
      value={{
        hasErrors: errors,
        showLoader: loader,
        listActivitysSubscribe,
        success,
        userId,
        activitysSubscribe,
      }}
    >
      {children}
    </ListActivitysSubscribeContext.Provider>
  );
};

function useListActivitysSubscribe(): ListActivitysSubscribeContextData {
  const context = useContext(ListActivitysSubscribeContext);

  if (!context) {
    throw new Error(
      "useListActivitysSubscribe must be used within an ListActivitysSubscribeProvider"
    );
  }

  return context;
}

export { ListActivitysSubscribeProvider, useListActivitysSubscribe };
