import React, { useState, useCallback, FormEvent } from "react";

import { CircularProgress, Grid, InputAdornment, TextField } from "@material-ui/core";

import Button from "../../../components/Button";

import { useChangePassword } from "../../../hooks/changePassword";
import { toast } from "react-toastify";
import { showToastr } from "../../../components/Toastr";

import { useParams } from "react-router-dom";
import Card from "../../../components/Card";
import { Redirect } from "../../../styles/components/Input/styles";
import { MdKeyboardBackspace } from "react-icons/md";

import PasswordIconOutline from "../../../assets/icons/icon_password_outline.svg";
import BackRouter from "../../../components/BackRouter";

interface ChangePasswordProps {
  token: string;
  password: string;
}

const ChangePassword: React.FC = () => {
  const [isFilled, setIsFilled] = useState(false);
  const [password, setPassword] = useState("");
  const [passConfirm, setPassConfirm] = useState("");
  const [passInvalid, setPassInvalid] = useState(false);

  const { changePassword, showLoader } = useChangePassword();

  const { token } = useParams<ChangePasswordProps>();

  const splitToken = token.replace("token=", "");

  console.log(`aqui ó ${splitToken}`);

  const handleInputFilled = useCallback(() => {
    if (password !== "" && passConfirm !== "") {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [password, passConfirm]);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setPassInvalid(false);
      const data = { password };
      if (passConfirm !== password) {
        setPassInvalid(true);
        showToastr(
          "As senhas não conferem! Tente novamente!",
          "error",
          toast.POSITION.BOTTOM_RIGHT
        );
      } else {
        try {
          changePassword({
            password: data.password,
          });
        } catch (err) {
          showToastr(
            "Erro interno no servidor! Tente novamente",
            "error",
            toast.POSITION.BOTTOM_RIGHT
          );
        }
      }
    },
    [password, passConfirm, changePassword]
  );

  return (
    <div style={{ marginTop: "200px" }}>
      <Card mr="350px" ml="350px" mt="-65px" padding="100px" radius="10px">
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={6} sm={12} style={{ maxWidth: "400px" }}>
              <TextField
                id="outlined-basic"
                fullWidth
                type="password"
                label="Criar nova senha"
                placeholder="Criar nova senha"
                variant="outlined"
                disabled={showLoader}
                error={passInvalid}
                value={password}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={PasswordIconOutline} alt="usuário" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setPassword(e.target.value.trim());
                }}
                onBlur={handleInputFilled}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={6} sm={12}>
              <TextField
                id="outlined-basic"
                fullWidth
                type="password"
                label="Digite a senha novamente"
                placeholder="Digite a senha novamente"
                variant="outlined"
                error={passInvalid}
                disabled={showLoader}
                value={passConfirm}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={PasswordIconOutline} alt="usuário" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setPassConfirm(e.target.value);
                }}
                onBlur={handleInputFilled}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "30px",
              maxWidth: "840px",
              float: "right",
            }}
          >
            <Redirect to="/" style={{ marginLeft: "20px" }}>
              <MdKeyboardBackspace />
              VOLTAR
            </Redirect>
            {showLoader ? (
              <CircularProgress style={{ marginRight: '10px' }} />
            ) : (
              <Button
                type="submit"
                theme={isFilled ? "success" : ""}
                hidden={isFilled ? false : true}
                right='10px'
              >
                MUDAR A SENHA
              </Button>
            )}
          </Grid>
        </form>
      </Card>
    </div>
  );
};

export default ChangePassword;
