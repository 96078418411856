/* eslint-disable no-restricted-globals */
import React, { createContext, useCallback, useState, useContext } from "react";
import api from "../services/api";

import { useHistory } from "react-router-dom";

import jwt from "jwt-decode";
import { AxiosResponse } from "axios";
import { showToastr } from "../components/Toastr";
import { toast } from "react-toastify";

import {
  IAuthState,
  ISignInCredentials,
  IAuthContextData,
} from "../models/SignInInterface";

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem("@SIEC:token");
    const user = localStorage.getItem("@SIEC:user");

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {} as IAuthState;
  });

  const history = useHistory();

  const [errors, setErrors] = useState(false);
  const [loader, setLoader] = useState(false);

  const signIn = useCallback(
    async ({ username, password }) => {
      setLoader(true);
      setErrors(false);
      await api({
        method: "get",
        url: "login",
        auth: {
          username,
          password,
        },
      })
        .then(async (response: AxiosResponse) => {
          setLoader(false);
          console.log(response.data);

          const { token } = response.data;

          const user: any = jwt(token);

          localStorage.setItem("@SIEC:token", token);
          localStorage.setItem("@SIEC:user", JSON.stringify(user));

          setData({ token, user });

          if (user.first_logon) {
            history.push("/updatePassword");
          }

          showToastr(
            `Seja bem vindo ao sisducacional ${user.name}`,
            "success",
            toast.POSITION.BOTTOM_RIGHT
          );
        })
        .catch((err) => {
          setLoader(false);
          setErrors(true);
          if (err.response.status === 403) {
            showToastr(
              "Usuário ou senha inválidos! Tente novamente",
              "error",
              toast.POSITION.BOTTOM_RIGHT
            );
          }
        });
    },
    [history]
  );

  const signOut = useCallback(() => {
    localStorage.removeItem("@SIEC:token");
    localStorage.removeItem("@SIEC:user");
    localStorage.removeItem("@SIEC:formValidate");

    setData({} as IAuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        hasErrors: errors,
        showLoader: loader,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
