import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 75px;
  margin-top: 40px;
  cursor: pointer;
`;

export const Paragraph = styled.p`
  margin-left: 10px;
`;
