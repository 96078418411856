import styled from 'styled-components';

interface TitleProps {
  weight?: any;
  fontFamily: string;
}

export const Container = styled.div<TitleProps>`
  font-weight: ${(props) => props.weight ? props.weight : 500};
  font-family: ${(props) => props.fontFamily}
`;
